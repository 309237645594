import {
	getProjectsDetailsUrl,
	getSelectionOfMaterialFormUrl,
	getSelectionOfMaterialFormWithMaterialUrl,
} from "@shared/routes"
import {
	CertificateDetailsFormInputs,
	useCertificateCreationFormStore,
	useLoadingStore,
} from "@shared/stores"
import { FeatureToLoading } from "@shared/stores/loading/types"
import { FormWithSummary } from "@shared/ui"
import { CanvasCard, icon } from "@sustainability/fundamental"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useCertificateDetailsValidationSchema } from "../models"
import { useCertificateDetailsFormStore } from "../store"
import { CertificateDetailsContent } from "./certificate-details-content"
import { CertificateDetailsSummary } from "./certificate-details-summary"
import { ReviewCertificateDetailsModal } from "./review-certificate-details-modal"

interface CertificatesDetailsFormProps {
	pageName: string
	isLoading: boolean
	contractNumber?: string
	materialNumber?: string
}

export const CertificatesDetailsForm: React.FC<
	CertificatesDetailsFormProps
> = ({ pageName, isLoading, contractNumber, materialNumber }) => {
	const FEATURE_NAME = "certificatesDetailsForm"
	const featureToLoading: FeatureToLoading = {
		page: pageName,
		feature: FEATURE_NAME,
	}

	const { t } = useTranslation("features", { keyPrefix: FEATURE_NAME })

	const [isModalOpen, setIsModalOpen] = useState(false)

	const navigate = useNavigate()

	const [
		selectionOfCertificateTypeFormState,
		certificateDetailsFormInputs,
		createdProjectId,
		setCertificateDetailsFormState,
		submitCertificate,
		resetCertificateData,
	] = useCertificateCreationFormStore(state => [
		state.selectionOfCertificateTypeFormState,
		state.certificateDetailsFormInputs,
		state.createdProjectId,
		state.setCertificateDetailsFormState,
		state.submitCertificate,
		state.resetCertificateData,
	])

	const [addFeatureToLoading, removeFeatureFromLoading] = useLoadingStore(
		state => [state.addFeatureToLoading, state.removeFeatureFromLoading],
	)

	const validationSchema = useCertificateDetailsValidationSchema(t)

	const fetchCountries = useCertificateDetailsFormStore(
		state => state.fetchCountries,
	)

	const fetchCountryData = async () => {
		addFeatureToLoading(featureToLoading)

		await fetchCountries()

		removeFeatureFromLoading(featureToLoading)
	}

	const handleModalSubmit = async () => {
		addFeatureToLoading({ feature: FEATURE_NAME })

		await submitCertificate()
		resetCertificateData()

		removeFeatureFromLoading({ feature: FEATURE_NAME })

		navigate(getProjectsDetailsUrl(createdProjectId ?? ""))
	}

	useEffect(() => {
		fetchCountryData()
	}, [])

	if (isLoading) {
		return <></>
	}

	const initialValues: CertificateDetailsFormInputs = {
		project:
			certificateDetailsFormInputs?.project ??
			selectionOfCertificateTypeFormState?.project,
		companyName: certificateDetailsFormInputs?.companyName,
		street: certificateDetailsFormInputs?.street,
		zipCode: certificateDetailsFormInputs?.zipCode,
		city: certificateDetailsFormInputs?.city,
		country: certificateDetailsFormInputs?.country,
	}

	const handleSubmit = (formInputs: CertificateDetailsFormInputs) => {
		setCertificateDetailsFormState(formInputs)
		setIsModalOpen(true)
	}

	const handleBack = () => {
		const previousStepUrl =
			!!contractNumber && !!materialNumber
				? getSelectionOfMaterialFormWithMaterialUrl(
						contractNumber,
						materialNumber,
					)
				: getSelectionOfMaterialFormUrl()

		navigate(previousStepUrl)
	}

	return (
		<CanvasCard className="h-[calc(100vh-266px)]">
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
				validateOnMount
			>
				{({ isValid }) => (
					<FormWithSummary
						data-cy="certificates-details-form"
						formContent={
							<CertificateDetailsContent
								featureName={FEATURE_NAME}
							/>
						}
						summaryTitle={t("summary.title")}
						summaryContent={
							<CertificateDetailsSummary
								featureName={FEATURE_NAME}
							/>
						}
						submitTitle={t("submitTitle")}
						submitIcon={icon.mdiCheck}
						backTitle={t("backTitle")}
						onBack={handleBack}
						isValid={isValid}
					/>
				)}
			</Formik>
			<ReviewCertificateDetailsModal
				featureName={FEATURE_NAME}
				isModalOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onSubmit={handleModalSubmit}
			/>
		</CanvasCard>
	)
}
