import {
	cycleApi,
	Cycle,
	CycleSearchParams,
	CycleStatus,
} from "@carbonbank/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { CycleStats } from "./types"

const DEFAULT_PAGE = 0

type State = {
	cycleViews: Cycle[]
	nextSavingsPage: number
	cycle?: Cycle
	cycleStats?: CycleStats
	hasMoreSavingsData: boolean
	isLoadingCarbonSavings: boolean
}
type Actions = {
	resetStore: () => void
	fetchCycleShortViewData: (filters: CycleSearchParams) => Promise<void>
	fetchCycleById: (id: string) => Promise<void>
	updateCycleState: (cycle: string, status: CycleStatus) => void
	updateCycleStateForSpecificCycle: (
		cycle: string,
		status: CycleStatus,
	) => void
}

export const useCarbonSavingsStore = create<State & Actions>()(
	immer((set, get) => ({
		cycleViews: [],
		nextSavingsPage: 1,
		cycle: undefined,
		cycleStats: undefined,
		hasMoreSavingsData: false,
		isLoadingCarbonSavings: false,

		resetStore: () => {
			set(state => {
				state.cycleViews = []
				state.nextSavingsPage = 1
				state.cycle = undefined
				state.cycleStats = undefined
				state.hasMoreSavingsData = false
				state.isLoadingCarbonSavings = false
			})
		},
		fetchCycleShortViewData: async (filters: CycleSearchParams) => {
			if (!filters.plantNumber) return

			const isDefaultPage = filters.page === DEFAULT_PAGE

			if (isDefaultPage) {
				set(state => {
					state.cycleViews = []
					state.nextSavingsPage = filters.page!
				})
			}

			filters.page = get().nextSavingsPage

			try {
				set(state => {
					state.isLoadingCarbonSavings = true
				})

				const response = await cycleApi.getCycleShortViewData(filters)

				set(state => {
					if (isDefaultPage) {
						state.cycleViews = response.cycles
					} else {
						state.cycleViews = [
							...get().cycleViews,
							...response.cycles,
						]
					}
					state.hasMoreSavingsData = response.cycles.length > 0
					state.cycleStats = { ...response }
					state.nextSavingsPage = ++filters.page!
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch carbon savings")
			} finally {
				set(state => {
					state.isLoadingCarbonSavings = false
				})
			}
		},
		fetchCycleById: async (id: string) => {
			try {
				const response = await cycleApi.getCycleById(id)

				set(state => {
					state.cycle = response
				})
			} catch (error) {
				spawnErrorToast("Failed to carbon savings cycle")
			} finally {
				set(state => {
					state.isLoadingCarbonSavings = false
				})
			}
		},
		updateCycleState: (cycle, status) => {
			set(state => {
				const cycles = state.cycleViews
				if (!cycles || cycles.length === 0) {
					return
				}

				const cycleToUpdate = cycles.find(c => c.cycle === cycle)
				if (!cycleToUpdate) {
					return
				}

				cycleToUpdate.status = status
				state.cycleViews = cycles
			})
		},
		updateCycleStateForSpecificCycle: (cycle, status) => {
			set(state => {
				if (!state.cycle || state.cycle.cycle !== cycle) {
					return
				}

				state.cycle.status = status
			})
		},
	})),
)
