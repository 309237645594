import NoMaterialsImport from "@shared/assets/no-materials.png"

import LogoDnvImport from "./dnvassurance.png"
import EvoBuildImport from "./evoBuild.png"
import translationsDe from "./locales/de-DE.json"
import translationsEn from "./locales/en-GB.json"
import NotFound from "./notFoundPage.png"
import TransactionsImage from "./transactionOverView.png"

export const LogoDnv = LogoDnvImport
export const NoMaterials = NoMaterialsImport
export const Transactions = TransactionsImage
export const LogoEvoBuild = EvoBuildImport
export const NotFoundPage = NotFound
export const globalTranslations = {
	en: translationsEn,
	de: translationsDe,
}
