import {
	accessDenied,
	notFound,
	serviceUnavailable,
	unauthorized,
} from "@shared/routes/technical"
import { Layout } from "@shared/ui"

import { AccessDeniedPage } from "../access-denied"
import { NotFoundPage } from "../not-found"
import { ServiceUnavailablePage } from "../service-unavailable"
import { UnauthorizedPage } from "../unauthorized"

export default [
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				path: accessDenied,
				element: <AccessDeniedPage />,
			},
			{
				path: serviceUnavailable,
				element: <ServiceUnavailablePage />,
			},
			{
				path: unauthorized,
				element: <UnauthorizedPage />,
			},
			{
				path: notFound,
				element: <NotFoundPage />,
			},
		],
	},
]
