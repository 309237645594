import { useLoadingStore } from "@shared/stores"
import { FeatureToLoading } from "@shared/stores/loading/types"
import { NoProjects, RoundedBox } from "@shared/ui"
import { CardHeader, clx, Table } from "@sustainability/fundamental"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useCertificatesForMaterialStore } from "../store"
import { useCertificatesTableConfig } from "./useCertificatesTableConfig"

interface CertificatesListProps {
	className?: string
	pageName: string
	contractNumber: string
	materialNumber: string
	isLoading: boolean
}

export const CertificatesList: React.FC<CertificatesListProps> = ({
	className,
	pageName,
	contractNumber,
	materialNumber,
	isLoading,
}) => {
	const FEATURE_NAME = "certificatesList"
	const featureToLoading: FeatureToLoading = {
		page: pageName,
		feature: FEATURE_NAME,
	}

	const { t } = useTranslation("features", { keyPrefix: FEATURE_NAME })

	const { t: tTableHeaders } = useTranslation("features", {
		keyPrefix: `${FEATURE_NAME}.certificatesTableHeaders`,
	})

	const tableConfig = useCertificatesTableConfig(tTableHeaders)

	const [certificates, fetchCertificatesForMaterialItem, resetStore] =
		useCertificatesForMaterialStore(state => [
			state.certificates,
			state.fetchCertificatesForMaterialItem,
			state.resetStore,
		])

	const [addFeatureToLoading, removeFeatureFromLoading] = useLoadingStore(
		state => [state.addFeatureToLoading, state.removeFeatureFromLoading],
	)

	const fetchCertificatesForMaterialItemData = async () => {
		addFeatureToLoading(featureToLoading)

		await fetchCertificatesForMaterialItem(contractNumber, materialNumber)

		removeFeatureFromLoading(featureToLoading)
	}

	useEffect(() => {
		fetchCertificatesForMaterialItemData()

		return () => resetStore()
	}, [])

	if (isLoading) {
		return <></>
	}

	return (
		<RoundedBox
			data-cy="certificates-list-for-material-container"
			className={clx("py-4 sm:py-6", className)}
		>
			<CardHeader
				data-cy="certificates-list-for-material-header"
				className="pl-4 md:pl-6"
			>
				{t("title")}
			</CardHeader>
			{certificates.length > 0 ? (
				<div className="md:px-2 lg:px-3">
					<Table
						data-cy="certificates-list-for-material-table"
						config={tableConfig}
						data={certificates}
						breakpoint="lg"
					/>
				</div>
			) : (
				<div className="flex justify-center">
					<NoProjects />
				</div>
			)}
		</RoundedBox>
	)
}
