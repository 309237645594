import { AxiosError } from "axios"

export const statusCheckInterceptor = async (
	error: AxiosError,
): Promise<AxiosError> => {
	const SERVER_ERROR_CODE = 500

	const errorCodeToPath: Record<number, string> = {
		401: "/unauthorized",
		403: "/access-denied",
		404: "/not-found",
		500: "/service-unavailable",
		503: "/service-unavailable",
	}
	const statusCode = error.response?.status || 0
	let errorPagePath = errorCodeToPath[statusCode]

	if (errorPagePath) {
		errorPagePath =
			statusCode >= SERVER_ERROR_CODE
				? `${errorPagePath}?backUrl=${window.location.pathname}&errorMessage=${encodeURIComponent(error.message)}`
				: errorPagePath

		window.location.href = errorPagePath
	}

	return Promise.reject(error)
}
