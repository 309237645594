import { useSelectionOfCertificateTypeFormStore } from "@features/selection-of-certificate-type-form/store"
import { SelectionOfCertificateFormInputs } from "@shared/stores"
import {
	CardHeader,
	Input,
	Radio,
	RadioGroup,
} from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface SelectProjectSectionProps {
	featureName: string
}

export const SelectProjectSection: React.FC<SelectProjectSectionProps> = ({
	featureName,
}) => {
	const { t } = useTranslation("features", {
		keyPrefix: `${featureName}.content.selectProjectSection`,
	})
	const { setFieldValue } =
		useFormikContext<SelectionOfCertificateFormInputs>()

	const projects = useSelectionOfCertificateTypeFormStore(
		state => state.projects,
	)

	const [selectedProjectId, setSelectedProjectId] = useState(
		projects![0].projectReferenceId,
	)

	useEffect(() => {
		setFieldValue("projectId", selectedProjectId)
		const newProjectInput = document.querySelector(
			'input[name="newProject"]',
		) as HTMLInputElement

		!selectedProjectId ? newProjectInput?.focus() : newProjectInput?.blur()
	}, [selectedProjectId])

	const handleSelectedProjectIdChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const value = event.target.value
		setSelectedProjectId(value)
	}

	const radioButtons = [
		...projects!.map(project => (
			<Radio
				key={`${project.name}-${project.projectReferenceId}`}
				label={project.name}
				name="projectId"
				value={project.projectReferenceId}
				checked={selectedProjectId === project.projectReferenceId}
				onChange={handleSelectedProjectIdChange}
			/>
		)),
		<Radio
			key="newProject"
			className="items-center"
			name="projectId"
			value=""
			checked={!selectedProjectId}
			onChange={handleSelectedProjectIdChange}
		>
			<Input
				className="w-full p-x-[16px] [&>div>div>input]:min-h-[48px]"
				name="newProject"
				placeholder={t("createNewProjectPlaceholder")}
			/>
		</Radio>,
	]

	return (
		<div>
			<CardHeader>{t("title")}</CardHeader>
			<RadioGroup className="xl:w-[62.75%] lg:w-full [&>tr]:border-b-solid [&>tr]:border-b-[1px]">
				{radioButtons}
			</RadioGroup>
		</div>
	)
}
