import { carbonBankWithAuthClient } from "../client"
import { generateGetUrl } from "../utils"
import {
	CycleSearchParams,
	CycleViewData,
	Cycle,
	ChangeCycleStatus,
	TotalDeposits,
} from "./types"

const PAGE_SIZE = 10

export const cycleApi = {
	getCycleShortViewData: (
		params: CycleSearchParams,
	): Promise<CycleViewData> =>
		carbonBankWithAuthClient
			.get(
				generateGetUrl("/cycles", {
					plantNumber: params.plantNumber,
					page: params.page,
					pageSize: PAGE_SIZE,
				}),
			)
			.then(response => response.data),
	getCycleById: (id: string): Promise<Cycle> =>
		carbonBankWithAuthClient
			.get(`/cycles/${id}`)
			.then(response => response.data),
	changeCycleStatus: (
		cycle: string,
		data: ChangeCycleStatus,
	): Promise<void> => carbonBankWithAuthClient.put(`/cycles/${cycle}`, data),
	getTotalDeposits: (year: number): Promise<TotalDeposits> =>
		carbonBankWithAuthClient
			.get(generateGetUrl("/cycles/total-deposits", { year }))
			.then(response => response.data),
}
