import {
	carbonSavingsRoutes,
	commonRoutes,
	contractsRoutes,
	customerRoutes,
	microSiteRoutes,
	materialRoutes,
	projectRoutes,
	transactionsRoutes,
	adminRoutes,
	technicalRoutes,
} from "@carbonbank/pages"

export const carbonBankRoutes = [
	...carbonSavingsRoutes,
	...contractsRoutes,
	...commonRoutes,
	...transactionsRoutes,
	...microSiteRoutes,
	...customerRoutes,
	...materialRoutes,
	...projectRoutes,
	...adminRoutes,
	...technicalRoutes,
]
