import { RoundedBox } from "@carbonbank/shared/ui"
import { useLoadingStore } from "@shared/stores"
import { FeatureToLoading } from "@shared/stores/loading/types"
import { Button, icon, Loading, Table } from "@sustainability/fundamental"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useInvoicesForMaterialStore } from "../../store"
import { useInvoicesTableConfig } from "./useInvoicesTableConfig"

interface InvoicesListProps {
	featureName: string
	contractNumber: string
	materialNumber: string
}

export const InvoicesTable: React.FC<InvoicesListProps> = ({
	featureName,
	contractNumber,
	materialNumber,
}) => {
	const featureToLoading: FeatureToLoading = {
		feature: featureName,
	}

	const { t } = useTranslation("features", { keyPrefix: featureName })

	const { t: tTableHeaders } = useTranslation("features", {
		keyPrefix: "invoicesList.invoicesTableHeaders",
	})

	const tableConfig = useInvoicesTableConfig(tTableHeaders)

	const [invoices, canLoadMore, fetchInvoicesForMaterialItem] =
		useInvoicesForMaterialStore(state => [
			state.invoices,
			state.canLoadMore,
			state.fetchInvoicesForMaterialItem,
		])

	const [
		featuresToLoading,
		isFeatureLoading,
		addFeatureToLoading,
		removeFeatureFromLoading,
	] = useLoadingStore(state => [
		state.featuresToLoading,
		state.isFeatureLoading,
		state.addFeatureToLoading,
		state.removeFeatureFromLoading,
	])

	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		const loading = isFeatureLoading(featureName)

		if (loading !== isLoading) {
			setLoading(loading)
		}
	}, [featuresToLoading])

	const fetchInvoicesForMaterialItemData = async () => {
		addFeatureToLoading(featureToLoading)

		await fetchInvoicesForMaterialItem(contractNumber, materialNumber)

		removeFeatureFromLoading(featureToLoading)
	}

	return (
		<RoundedBox
			data-cy="invoices-for-material-item-table-container"
			className="flex flex-col w-full"
		>
			<div className="flex flex-col justify-center md:px-2 lg:px-3">
				<Table
					data-cy="invoices-for-material-item-table"
					config={tableConfig}
					data={invoices}
					breakpoint="lg"
				/>
				{canLoadMore && (
					<div
						data-cy="invoices-for-material-item-table-show-more-invoices-button-container"
						className="text-center mt-6"
					>
						<Button
							data-cy="invoices-for-material-item-table-show-more-invoices-button"
							variant="tertiary"
							icon={
								isLoading ? (
									<Loading
										data-cy="invoices-for-material-item-table-show-more-invoices-button-loader"
										size="xsmall"
										variant="primary"
									/>
								) : (
									icon.mdiArrowDown
								)
							}
							onClick={async () => {
								if (isLoading) {
									return
								}

								await fetchInvoicesForMaterialItemData()
							}}
						>
							{t("buttonShowMoreInvoicesTitle")}
						</Button>
					</div>
				)}
			</div>
		</RoundedBox>
	)
}
