import { carbonBankWithAuthClient } from "@carbonbank/api/client"
import { generateGetUrl } from "@carbonbank/api/utils"

import { PaginationRequest } from "../types"
import { OutboxMessagesResponse } from "./types"

export const outboxMessagesApi = {
	get: (
		paginationRequest: PaginationRequest,
	): Promise<OutboxMessagesResponse> =>
		carbonBankWithAuthClient
			.get(
				generateGetUrl(`/outbox-messages`, {
					...paginationRequest,
				}),
			)
			.then(response => response.data),
	retry: (id: string): Promise<void> =>
		carbonBankWithAuthClient.post(
			generateGetUrl(`/outbox-messages/${id}/retry`),
		),
}
