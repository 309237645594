export interface CycleSearchParams {
	plantNumber?: string
	page?: number
}

export enum CycleStatus {
	New = "New",
	Rejected = "Rejected",
	RequireMoreInformation = "RequireMoreInformation",
	AwaitingAssurance = "AwaitingAssurance",
	Assured = "Assured",
	Failed = "Failed",
}

export interface CycleStepView {
	sensorId: string
	name: string
	timestamp: string
	totalSavings: number
	biogenic: number
	fossil: number
	unit: string
}

export interface Cycle {
	cycle: string
	plant: string
	status: CycleStatus
	serialNumber?: string
	unit: string
	totalSavings: number
	biogenicSavings: number
	percentBiogenic: number
	fossilSavings: number
	percentFossil: number
	buffer: number
	assuranceTimestamp?: string
	shipmentDate: string
	co2PumpedIntoStorageDate: string
	steps?: CycleStepView[]
}

export interface CycleViewData {
	totalSavingsToBeAssured: number
	totalNewCycles: number
	totalCyclesInValidation: number
	cycles: Cycle[]
}

export interface RequestInformationDetails {
	fullName: string
	email: string
	comment: string
	buttonLink: string
}

export interface ChangeCycleStatus {
	status: CycleStatus
	serialNumber: string
	reasonDetails?: RequestInformationDetails
}

export interface TotalDeposits {
	sum: number
}
