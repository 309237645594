import { useAuthStore } from "@carbonbank/store"
import { CentralizedContainerBox } from "@shared/ui"
import { ErrorActionCard } from "@widgets/error-action-card"
import React from "react"
import { useTranslation } from "react-i18next"

export const UnauthorizedPage: React.FC = () => {
	const { t } = useTranslation("pages", { keyPrefix: "unauthorized" })
	const login = useAuthStore(state => state.login)

	return (
		<CentralizedContainerBox data-cy="unauthorized-page-container">
			<ErrorActionCard
				title={t("title")}
				description={t("description")}
				type="unauthorized"
				primaryButtonTitle={t("primaryButtonTitle")}
				onPrimaryButtonClick={login}
			/>
		</CentralizedContainerBox>
	)
}
