import { CycleStatus } from "@carbonbank/api"
import { ScenarioVariant } from "@carbonbank/store"
import {
	Button,
	DropdownItem,
	DropdownWithButton,
	icon,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface PageHeaderButtonsProps {
	status: CycleStatus
	onScenario: (scenario: ScenarioVariant) => void
}

export const PageHeaderButtons: React.FC<PageHeaderButtonsProps> = ({
	status,
	onScenario,
}: PageHeaderButtonsProps) => {
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})

	return (
		<div className="flex gap-x-3" data-cy="action-buttons">
			<Button
				size="large"
				onClick={() => {
					onScenario("assure")
				}}
			>
				{tCommon("assure")}
			</Button>
			{status === CycleStatus.RequireMoreInformation ? (
				<Button
					size="large"
					variant="negative"
					onClick={() => {
						onScenario("reject")
					}}
				>
					{tCommon("reject")}
				</Button>
			) : (
				<>
					<Button
						className="hidden md:block"
						size="large"
						variant="secondary"
						onClick={() => {
							onScenario("requestInformation")
						}}
					>
						{tCommon("requestInformation")}
					</Button>

					<DropdownWithButton
						className="block md:hidden"
						data-cy="action-trigger"
						buttonProps={{
							square: true,
							variant: "secondary",
							icon: icon.mdiDotsHorizontal,
							size: "large",
						}}
					>
						<DropdownItem
							title={tCommon("requestInformation")}
							onClick={() => {
								onScenario("requestInformation")
							}}
						/>
					</DropdownWithButton>
				</>
			)}
		</div>
	)
}
