import { Cycle } from "@carbonbank/api"
import { formatNumber } from "@carbonbank/utils"
import {
	DashboardCard,
	CardHeader,
	TextRegular,
	Kpi,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface FuelStatsCardProps {
	cycle: Cycle
}

export const FuelStatsCard: React.FC<FuelStatsCardProps> = ({
	cycle: {
		biogenicSavings,
		fossilSavings,
		totalSavings,
		percentBiogenic,
		percentFossil,
	},
}: FuelStatsCardProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "carbonSavingsDetails.components.fuelStats",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.fuel",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	const fuelStatsUnit = tUnits("kg")

	return (
		<DashboardCard data-cy="fuel-stats">
			<CardHeader data-cy="title" className="max-sm:text-wrap">
				{t("title")}
			</CardHeader>
			<div className="flex gap-x-6 max-sm:flex-col">
				<div data-cy="fuel-usage">
					<TextRegular
						data-cy="title"
						className="text-foreground_faded"
					>
						{t("fuelUsageTitle")}
					</TextRegular>
					<div className="flex gap-x-6 mt-12px" data-cy="stats">
						<Kpi
							size="small"
							label={tCommon("biogenic")}
							value={percentBiogenic}
							unit="%"
						/>
						<Kpi
							size="small"
							label={tCommon("fossil")}
							value={percentFossil}
							unit="%"
						/>
					</div>
				</div>

				<div data-cy="fuel-pumped">
					<TextRegular
						data-cy="title"
						className="text-foreground_faded"
					>
						{t("fuelPumpedTitle")}
					</TextRegular>
					<div
						className="flex flex-wrap gap-y-2 gap-x-6 mt-12px"
						data-cy="stats"
					>
						<Kpi
							size="small"
							label={tCommon("total")}
							value={formatNumber(totalSavings)}
							unit={fuelStatsUnit}
						/>
						<Kpi
							size="small"
							label={tCommon("biogenic")}
							value={formatNumber(biogenicSavings)}
							unit={fuelStatsUnit}
						/>
						<Kpi
							size="small"
							label={tCommon("fossil")}
							value={formatNumber(fossilSavings)}
							unit={fuelStatsUnit}
						/>
					</div>
				</div>
			</div>
		</DashboardCard>
	)
}
