import { CycleStatus } from "@carbonbank/api"
import { ContainerBox, LoadingPage } from "@carbonbank/components"
import {
	useCarbonSavingsModalStore,
	useCarbonSavingsStore,
	useFilterStore,
} from "@carbonbank/store"
import {
	Button,
	CanvasCard,
	PageHeader,
	icon,
	CardHeader,
	Loading,
	FilterForm,
} from "@sustainability/fundamental"
import { useFilter } from "@sustainability/fundamental-hooks"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { AssureCarbonSavingsModal } from "../components"
import { carbonSavingsFilterConfig } from "./carbonSavingsFilter.config"
import { AssureStats, SavingsTable } from "./components"

const DEFAULT_PAGE = 0

interface CarbonSavingsFilterProps {
	plantNumber: string
}

export const CarbonSavings: React.FC = () => {
	const { t } = useTranslation("cs", {
		keyPrefix: "carbonSavings.page",
	})

	const initialFilter: CarbonSavingsFilterProps = useMemo(
		() => ({
			plantNumber: "",
		}),
		[],
	)
	const [
		cycleViews,
		cycleStats,
		hasMoreSavingsData,
		isLoadingCarbonSavings,
		fetchCycleShortViewData,
		resetStore,
		updateCycleStatus,
	] = useCarbonSavingsStore(state => [
		state.cycleViews,
		state.cycleStats,
		state.hasMoreSavingsData,
		state.isLoadingCarbonSavings,
		state.fetchCycleShortViewData,
		state.resetStore,
		state.updateCycleState,
	])

	const updateFilter = useCallback(
		async (newFilter?: CarbonSavingsFilterProps) => {
			setLoading(true)

			if (!newFilter) {
				return
			}

			await fetchCycleShortViewData({
				plantNumber: newFilter.plantNumber,
				page: DEFAULT_PAGE,
			})

			setLoading(false)
		},
		[fetchCycleShortViewData],
	)

	const [filter, setFilter] = useFilter<CarbonSavingsFilterProps>(
		initialFilter,
		updateFilter,
	)

	const openModal = useCarbonSavingsModalStore(state => state.openModal)

	const [plants, fetchPlantFilterData] = useFilterStore(state => [
		state.plants,
		state.fetchPlantFilterData,
	])

	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		resetStore()
		fetchPlantFilterData()
	}, [fetchPlantFilterData, resetStore])

	useEffect(() => {
		const shouldSetFilter =
			filter &&
			!filter.plantNumber &&
			plants &&
			plants.length > 0 &&
			plants[0].plantNumber

		if (shouldSetFilter) {
			setFilter({ plantNumber: plants[0].plantNumber })
		}
	}, [filter, setFilter, plants])

	const onModalSubmit = async (cycle: string, status: CycleStatus) => {
		if (!cycle) {
			return
		}
		updateCycleStatus(cycle, status)
	}

	if (!filter || !cycleStats || !cycleViews || !plants || isLoading) {
		return <LoadingPage title={t("title")} noContainerBox />
	}

	return (
		<>
			<PageHeader
				data-cy="page-title"
				className="self-center max-sm:flex max-sm:flex-col max-sm:flex-start max-sm:[&>div]:self-start max-sm:[&>div:last-child]:self-start max-sm:[&>div:last-child>form]:my-4 max-sm:[&>div:last-child>form]:p-0"
				aside={
					<FilterForm
						data-cy="filter-form"
						config={carbonSavingsFilterConfig(plants, t)}
						initialValues={{ ...filter }}
						onChange={data => {
							const newFilterState =
								data as unknown as CarbonSavingsFilterProps

							setFilter(newFilterState)
						}}
					/>
				}
			>
				{t("title")}
			</PageHeader>
			<ContainerBox>
				<AssureStats
					className="mb-24px"
					toBeAssured={cycleStats!.totalSavingsToBeAssured}
					newCycles={cycleStats!.totalNewCycles}
					inValidation={cycleStats!.totalCyclesInValidation}
				/>
			</ContainerBox>
			<CanvasCard data-cy="savings-list" className="flex-grow">
				<CardHeader data-cy="savings-list-header">
					{t("carbonSavingsTitle")}
				</CardHeader>
				<SavingsTable
					data={cycleViews}
					onAssure={data => openModal(data, "assure")}
				/>
				{hasMoreSavingsData && (
					<div className="text-center mt-6">
						<Button
							data-cy="savings-history-button"
							variant="tertiary"
							icon={
								isLoadingCarbonSavings ? (
									<Loading size="xsmall" variant="primary" />
								) : (
									icon.mdiArrowDown
								)
							}
							onClick={() => {
								if (!isLoadingCarbonSavings) {
									fetchCycleShortViewData({
										plantNumber: filter.plantNumber,
									})
								}
							}}
						>
							{t("savingsHistory")}
						</Button>
					</div>
				)}
			</CanvasCard>
			<AssureCarbonSavingsModal onSubmit={onModalSubmit} />
		</>
	)
}
