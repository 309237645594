import { useAuthStore } from "@carbonbank/store"
import {
	getStartPageInfoByRoleTypes,
	IStartPageInfo,
} from "@carbonbank/utils/routesUtils"
import { CentralizedContainerBox } from "@shared/ui"
import { ErrorActionCard } from "@widgets/error-action-card"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export const NotFoundPage: React.FC = () => {
	const [startPageInfo, setStartPageInfo] = useState<
		IStartPageInfo | undefined
	>()

	const { t } = useTranslation("pages", { keyPrefix: "notFound" })
	const { t: tShared } = useTranslation("shared", {
		keyPrefix: "pages",
	})

	const navigate = useNavigate()

	const userRoles = useAuthStore(state => state.userRoles)

	const handleSecondaryButtonClick = () => {
		navigate(startPageInfo?.path || "/")
	}

	useEffect(() => {
		const roleTypes = userRoles?.map(x => x.roleType)
		const pageInfo = getStartPageInfoByRoleTypes(roleTypes)

		setStartPageInfo(pageInfo)
	}, [userRoles])

	return (
		<CentralizedContainerBox data-cy="not-found-page-container">
			<ErrorActionCard
				title={t("title")}
				description={t("description")}
				type="notFound"
				secondaryButtonTitle={t("secondaryButtonTitle", {
					page: startPageInfo ? tShared(startPageInfo.name) : "",
				})}
				onSecondaryButtonClick={handleSecondaryButtonClick}
			/>
		</CentralizedContainerBox>
	)
}
