import { Unit } from "@shared/api/types"
import { mapUnitToLocalizedUnit } from "@shared/lib"
import { ProjectSatusIcon } from "@shared/ui"
import { Kpi, LabelRegular, TextKpiMedium } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

import { ProjectDetailHeaderData } from "../../model"

export type ProjectDetailHeaderKpisProps = {
	data: ProjectDetailHeaderData
}

const MISSING_DATA = "-"

export const ProjectDetailHeaderKpis: React.FC<
	ProjectDetailHeaderKpisProps
> = ({ data }) => {
	const { t } = useTranslation("features", {
		keyPrefix: "projectDetailHeader.kpi",
	})

	if (!data) {
		return <></>
	}

	return (
		<div
			data-cy="project-detail-header-kpis"
			className="grid grid-cols-2 grid-rows-2 gap-4 justify-items-stretch px-9 xs:flex xs:justify-start xs:px-12 xs:gap-6 md:gap-12 flex-wrap"
		>
			<Kpi
				data-cy="project-detail-header-kpi-quantity"
				className="inline-flex flex-col items-start"
				size="small"
				label={t("quantity")}
				value={
					<div className="flex gap-2 items-baseline">
						{!!data.cementQuantity?.amount && (
							<>
								<TextKpiMedium data-cy="project-detail-header-kpi-quantity-amount">
									{data.cementQuantity?.amount ??
										MISSING_DATA}
								</TextKpiMedium>
								<LabelRegular>
									{mapUnitToLocalizedUnit(
										data.cementQuantity?.unit ?? Unit.Tons,
									)}
								</LabelRegular>
							</>
						)}
						{!!(
							data.cementQuantity?.amount &&
							data.concreteQuantity.amount
						) && " / "}
						{!!data.concreteQuantity?.amount && (
							<>
								<TextKpiMedium>
									{data.concreteQuantity?.amount ??
										MISSING_DATA}
								</TextKpiMedium>
								<LabelRegular>
									{mapUnitToLocalizedUnit(
										data.concreteQuantity?.unit ?? Unit.M3,
									)}
								</LabelRegular>
							</>
						)}
					</div>
				}
			/>
			<Kpi
				data-cy="project-detail-header-kpi-carbon-savings"
				className="inline-flex flex-col items-start"
				size="small"
				label={t("carbonSavings")}
				value={
					<div className="flex gap-2 items-baseline">
						<TextKpiMedium
							data-cy="project-detail-header-kpi-carbon-savings-amount"
							className="text-positive"
						>
							{data.carbonSavings?.amount ?? MISSING_DATA}
						</TextKpiMedium>
						<LabelRegular className="text-positive text-nowrap">
							{mapUnitToLocalizedUnit(Unit.KgCo2)}
						</LabelRegular>
					</div>
				}
			/>
			<Kpi
				data-cy="project-detail-header-kpi-status"
				className="inline-flex flex-col items-start"
				size="small"
				label={t("status.label")}
				value={
					<div className="flex gap-2 items-center">
						{data.status ? (
							<div className="flex gap-2 items-center h-[34px]">
								<ProjectSatusIcon status={data.status} />
								{t(`status.${data.status}`)}
							</div>
						) : (
							MISSING_DATA
						)}
					</div>
				}
				valueClassName="text-positive"
			/>
		</div>
	)
}
