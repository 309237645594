import { APP_NAME_ADMIN } from "@carbonbank/shared/config"
import { outbox } from "@shared/routes"
import { Layout } from "@shared/ui"
import { RouteObject } from "react-router-dom"

import { OutboxPage } from "../outbox"

export default [
	{
		path: outbox,
		element: <Layout productName={APP_NAME_ADMIN} />,
		children: [
			{
				index: true,
				element: <OutboxPage />,
			},
		],
	},
] satisfies RouteObject[]
