import { RoundedBox } from "@carbonbank/shared/ui"
import {
	Button,
	CardHeader,
	icon,
	spawnErrorToast,
} from "@sustainability/fundamental"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useMaterialsStore } from "../store"
import { MaterialsTable } from "./materials-table"
import { NoMaterials } from "./no-materials"

export const MaterialsList = () => {
	const { t } = useTranslation("features", { keyPrefix: "materialsList" })
	const [loading, setLoading] = useState(false)
	const [materials, canLoadMoreData, fetchMaterials] = useMaterialsStore(
		state => [state.materials, state.canLoadMoreData, state.fetchMaterials],
	)

	useEffect(() => {
		fetchData(false)
	}, [])

	const fetchData = async (loadTransferred: boolean) => {
		try {
			setLoading(true)
			await fetchMaterials(loadTransferred)
		} catch {
			spawnErrorToast(t("table.fetchError"))
		} finally {
			setLoading(false)
		}
	}

	return (
		<RoundedBox
			data-cy="available-materials-card"
			className="flex flex-col py-4 md:py-6"
		>
			<div className="flex flex-col justify-center w-full lg:gap-6 lg:px-3">
				{!!(loading || materials?.length) && (
					<>
						<CardHeader className="pl-4 lg:pl-3 mt-3">
							{t("title")}
						</CardHeader>
						<MaterialsTable
							loading={loading}
							data={materials}
							canLoadMoreData={canLoadMoreData}
							loadData={fetchData}
						/>
						{canLoadMoreData && (
							<div
								className="text-center"
								data-cy="materials-list-load-more-data"
							>
								<Button
									variant="tertiary"
									icon={icon.mdiArrowDown}
									onClick={() => {
										if (!loading) {
											fetchData(true)
										}
									}}
								>
									{t("loadMoreData")}
								</Button>
							</div>
						)}
					</>
				)}
				{!loading && !materials?.length && <NoMaterials />}
			</div>
		</RoundedBox>
	)
}
