import { LoadingPage } from "@carbonbank/components"
import { ActivityHistory } from "@features/activity-history"
import { CertificatesList } from "@features/project-detail-certificates"
import { ProjectDetailHeader } from "@features/project-detail-header"
import { materials } from "@shared/routes"
import { useLoadingStore } from "@shared/stores"
import { DnvCarbonSavingsCard } from "@shared/ui/dnv-carbon-savings-card"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

export const ProjectDetail = () => {
	const PAGE_NAME = "projectDetail"
	const isLoading = useLoadingStore(state => state.isPageLoading(PAGE_NAME))
	const navigate = useNavigate()
	const { projectId } = useParams<{ projectId: string }>()
	const { t } = useTranslation("pages", { keyPrefix: "projectDetail" })

	if (!projectId) return null

	return (
		<>
			<ProjectDetailHeader
				projectId={projectId}
				pageName={PAGE_NAME}
				isLoading={isLoading}
			/>
			<div
				data-cy="project-details-body"
				className="flex flex-col gap-y-6 py-4 md:py-6 px-2 md:px-6"
			>
				<DnvCarbonSavingsCard
					className="order-1 md:order-2"
					isLoading={isLoading}
				/>
				<div className="order-2 md:order-1">
					<CertificatesList
						pageName={PAGE_NAME}
						projectId={projectId}
						isLoading={isLoading}
					/>
				</div>
				<div className="order-3 md:order-3">
					<ActivityHistory
						pageName={PAGE_NAME}
						projectId={projectId}
						isLoading={isLoading}
					/>
				</div>
			</div>
			{isLoading && (
				<LoadingPage
					backToPageUrlOnClick={() => navigate(materials)}
					backToPageLabel={t("backToMaterials")}
				/>
			)}
		</>
	)
}
