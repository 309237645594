import { PageLayout } from "@carbonbank/components"
import { APP_NAME_COMMON } from "@shared/config"
import { Outlet } from "react-router-dom"

export type LayoutProps = {
	productName?: string
}
export const Layout: React.FC<LayoutProps> = ({ productName }) => (
	<PageLayout productName={productName || APP_NAME_COMMON} navigation={[]}>
		<Outlet />
	</PageLayout>
)
