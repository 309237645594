import { useCarbonSavingsModalStore } from "@carbonbank/store"
import { Textarea } from "@sustainability/fundamental"
import { useDebounce } from "@sustainability/fundamental-hooks"
import { useFormikContext } from "formik"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const FormFieldsRequestInfoFlow: React.FC = () => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureModal.requestInfoFlow",
	})

	const { values, resetForm, setErrors, validateForm } = useFormikContext()
	const debouncedValues = useDebounce(values)
	const isOpen = useCarbonSavingsModalStore(state => state.isOpen)

	useEffect(() => {
		validateForm(debouncedValues).then(errors => {
			setErrors(errors)
		})
	}, [debouncedValues])

	useEffect(() => {
		if (!isOpen) {
			resetForm()
		}
	}, [isOpen])

	return (
		<Textarea
			name="comment"
			data-cy="comment"
			label={t("explanationLabel")}
			showValidationErrorOnTouched={false}
		/>
	)
}
