import { NoMaterials as NoContractsImage } from "@carbonbank/assets"
import { ContainerBox } from "@carbonbank/components"
import { Button, H1, TextLong } from "@sustainability/fundamental"
import { Link } from "react-router-dom"

interface NoContractsProps {
	title: string
	description: string
	backToPageUrl: string
	buttonTtitle: string
}
export const NoDataFound: React.FC<NoContractsProps> = ({
	title,
	description,
	backToPageUrl,
	buttonTtitle,
}: NoContractsProps) => {
	return (
		<ContainerBox withBottomMargin data-cy="no-data-found">
			<div className="flex flex-col justify-center items-center mt-8">
				<img
					src={NoContractsImage}
					alt="No Contracts"
					className="w-[24Opx] h-[154px]"
				/>
				<div className="flex flex-col items-center gap-6 mt-8 w-[400px]">
					<H1>{title}</H1>
					<TextLong className="text-center text-foreground">
						{description}
					</TextLong>
					<Link to={backToPageUrl}>
						<Button variant="primary">{buttonTtitle}</Button>
					</Link>
				</div>
			</div>
		</ContainerBox>
	)
}
