import { nameof } from "@shared/lib/utility-types/table"
import {
	Button,
	icon,
	TextRegular,
	useSortableTable,
} from "@sustainability/fundamental"
import { TFunction } from "i18next"

import { OutboxMessage } from "../../model/types"

export const useOutboxMessagesTableConfig = (
	t: TFunction<"features", string>,
	retryOutboxMessage: (id: string) => Promise<void>,
	copyToClipboard: (text: string) => void,
) =>
	useSortableTable<OutboxMessage>(
		[
			{
				id: "id",
				label: t("id"),
				render: ({ id }) => (
					<div className="group relative flex items-center gap-2">
						<TextRegular data-cy="id">{id}</TextRegular>
						<Button
							icon={icon.mdiContentCopy}
							className="absolute right-0 hidden group-hover:inline-block"
							variant="secondary"
							onClick={() => copyToClipboard(id)}
						/>
					</div>
				),
				sortable: false,
			},
			{
				id: "type",
				label: t("type"),
				headerClassName: "w-[300px]",
				valueClassName:
					"w-[250px] whitespace-nowrap overflow-hidden text-ellipsis",
				render: ({ type }) => (
					<div className="group relative flex items-center gap-2">
						<TextRegular data-cy="type">{type}</TextRegular>
						<Button
							icon={icon.mdiContentCopy}
							className="absolute right-0 hidden group-hover:inline-block"
							variant="secondary"
							onClick={() => copyToClipboard(type)}
						/>
					</div>
				),
			},
			{
				id: "data",
				label: "Data",
				headerClassName: "w-[250px]",
				valueClassName:
					"w-[250px] whitespace-nowrap overflow-hidden text-ellipsis",
				render: ({ data }) => (
					<div className="group relative flex items-center gap-2">
						<TextRegular data-cy="data">{data}</TextRegular>
						<Button
							icon={icon.mdiContentCopy}
							className="absolute right-0 hidden group-hover:inline-block"
							variant="secondary"
							onClick={() => copyToClipboard(data)}
						/>
					</div>
				),
				sortable: false,
			},
			{
				id: "error",
				label: t("error"),
				headerClassName:
					"w-[300px] whitespace-nowrap overflow-hidden text-ellipsis",
				valueClassName:
					"w-[300px] whitespace-nowrap overflow-hidden text-ellipsis",
				render: ({ error }) => (
					<div className="group relative flex items-center gap-2">
						<TextRegular data-cy="error">{error}</TextRegular>
						<Button
							icon={icon.mdiContentCopy}
							className="absolute right-0 hidden group-hover:inline-block"
							variant="secondary"
							onClick={() => copyToClipboard(error)}
						/>
					</div>
				),
				sortable: false,
			},
			{
				id: "created",
				label: t("created"),
				sortFunction: (
					{ created: createdLeft },
					{ created: createdRight },
				) => sortUTCDateStrings(createdLeft, createdRight),
				headerClassName: "w-[300px] text-right",
				valueClassName:
					"w-[300px] text-right whitespace-nowrap overflow-hidden text-ellipsis",
			},
			{
				id: "updated",
				label: t("updated"),
				sortFunction: (
					{ created: createdLeft },
					{ created: createdRight },
				) => sortUTCDateStrings(createdLeft, createdRight),
				headerClassName:
					"w-[300px] text-right whitespace-nowrap overflow-hidden text-ellipsis",
				valueClassName:
					"w-[300px] text-right whitespace-nowrap overflow-hidden text-ellipsis",
			},
			{
				id: undefined,
				label: t("actions"),
				headerClassName: "text-right w-[100px]",
				valueClassName: "text-right w-[100px]",
				render: ({ id }) => (
					<Button
						data-cy="button-retry"
						variant="primary"
						onClick={() => retryOutboxMessage(id)}
					>
						{t("retry")}
					</Button>
				),
				sortable: false,
			},
		],
		[t],
		{
			prefix: "outbox-table-row",
			postfixFieldName: nameof<OutboxMessage>("id"),
		},
		{ enableLocalSorting: true },
	)

const sortUTCDateStrings = (dateLeft: string, dateRight: string) =>
	new Date(dateLeft).getTime() - new Date(dateRight).getTime()
