import { getStorageItemByName } from "@carbonbank/utils/local-storage"
import { jwtDecode } from "jwt-decode"

import { BasicTokenContent, PersistedToken } from "./types"

const TOKEN_STORAGE_KEY = "CarbonBank"

export const isAccessTokenNotExpired = (
	accessToken: string | undefined,
): boolean => {
	if (!accessToken) {
		return false
	}

	const decoded: BasicTokenContent = jwtDecode<BasicTokenContent>(accessToken)
	const expiresAt = decoded?.exp && decoded.exp * 1000

	return Date.now() < expiresAt
}

export const getFreshToken = async (): Promise<PersistedToken | undefined> => {
	const currentToken = getStorageItemByName<PersistedToken>(TOKEN_STORAGE_KEY)
	const isTokenNotExpired = isAccessTokenNotExpired(currentToken?.accessToken)

	if (isTokenNotExpired) {
		return currentToken!
	}

	return
}
