import {
	GetCommentsRequest,
	CommentStatus,
	CommentType,
	CycleStatus,
	PermissionType,
} from "@carbonbank/api"
import { ContainerBox, LoadingPage } from "@carbonbank/components"
import {
	useAuthStore,
	useCarbonSavingsModalStore,
	useCarbonSavingsStore,
	useCommentsStore,
} from "@carbonbank/store"
import {
	PageHeader,
	LayoutTwoOneRatio,
	LayoutTwoOneRatioLeftColumn,
	LayoutTwoOneRatioRightColumn,
	FormFields,
} from "@sustainability/fundamental"
import { FormikHelpers } from "formik"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { getAssureSavingsUrl } from "../carbonSavingsRoutes"
import { AssureCarbonSavingsModal } from "../components"
import {
	CycleInformations,
	FuelStatsCard,
	PageHeaderButtons,
	SensorCard,
	CommentBoxCard,
} from "./components"

export const CarbonSavingsDetails: React.FC = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const { t } = useTranslation("cs", {
		keyPrefix: "carbonSavingsDetails.page",
	})

	const [
		cycle,
		fetchCycleById,
		updateCycleStateForSpecificCycle,
		resetStore,
	] = useCarbonSavingsStore(state => [
		state.cycle,
		state.fetchCycleById,
		state.updateCycleStateForSpecificCycle,
		state.resetStore,
	])

	const [comments, fetchComments, submitComment, defineCommentsType] =
		useCommentsStore(state => [
			state.comments,
			state.fetchComments,
			state.submitComment,
			state.defineCommentsType,
		])

	const [decodedToken, userPermissions] = useAuthStore(state => [
		state.decodedToken,
		state.userPermissions,
	])

	const openModal = useCarbonSavingsModalStore(state => state.openModal)

	const commentSearchParams: GetCommentsRequest = {
		relatedEntityId: id ?? "",
		type: CommentType.CarbonSavings,
	}

	useEffect(() => {
		if (!id) {
			return navigate("/")
		}

		resetStore()
		fetchCycleById(id)
		fetchComments(commentSearchParams)
		defineCommentsType(CommentType.CarbonSavings)
	}, [id])

	const onModalSubmit = async (cycle: string, status: CycleStatus) => {
		updateCycleStateForSpecificCycle(cycle, status)
		await fetchComments(commentSearchParams)
	}

	const onCommentSubmit = async (
		{ comment }: FormFields,
		{ resetForm }: FormikHelpers<FormFields>,
	) => {
		await submitComment({
			username: decodedToken?.name ?? "",
			relatedEntityId: id!,
			status: CommentStatus.New,
			type: CommentType.CarbonSavings,
			content: comment,
		})
		await fetchComments(commentSearchParams!)
		resetForm()
	}

	if (!cycle) {
		return (
			<LoadingPage
				backToPageUrlOnClick={() => navigate(getAssureSavingsUrl())}
				backToPageLabel={t("backToPageLabel")}
				title={t("title", {
					id,
				})}
			/>
		)
	}

	const { steps, status } = cycle
	const shouldDisplayActionButtons =
		[CycleStatus.New, CycleStatus.RequireMoreInformation].includes(
			status,
		) &&
		userPermissions?.some(
			x => x.permissionType === PermissionType.ManageSavings,
		)

	return (
		<>
			<PageHeader
				backToPageUrlOnClick={() => navigate(getAssureSavingsUrl())}
				backToPageLabel={t("backToPageLabel")}
				data-cy="page-title"
				aside={
					shouldDisplayActionButtons && (
						<PageHeaderButtons
							status={status}
							onScenario={scenario => {
								openModal(cycle, scenario)
							}}
						/>
					)
				}
				className="[&>div>div]:ml-4 md:[&>div>div]:ml-6 px-8"
			>
				{t("title", {
					id,
				})}
			</PageHeader>
			<ContainerBox>
				<div className="mb-24px">
					<CycleInformations cycle={cycle} />
				</div>
				<LayoutTwoOneRatio>
					<LayoutTwoOneRatioLeftColumn
						className="flex flex-col gap-y-6"
						breakpoint="xl"
					>
						<FuelStatsCard cycle={cycle} />
						<SensorCard steps={steps || []} />
					</LayoutTwoOneRatioLeftColumn>
					<LayoutTwoOneRatioRightColumn breakpoint="xl">
						<CommentBoxCard
							comments={comments}
							onSubmit={onCommentSubmit}
						/>
					</LayoutTwoOneRatioRightColumn>
				</LayoutTwoOneRatio>
				<AssureCarbonSavingsModal onSubmit={onModalSubmit} />
			</ContainerBox>
		</>
	)
}
