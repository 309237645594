import { CycleStepView } from "@carbonbank/api"
import { nameof } from "@carbonbank/shared/lib/utility-types/table"
import { RoundedBox } from "@carbonbank/shared/ui"
import { formatTimestamp, formatNumberWithUnit } from "@carbonbank/utils"
import {
	CardHeader,
	TextMedium,
	TextRegular,
	Label,
	useSortableTable,
	Table,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface SensorCardProps {
	steps: CycleStepView[]
}

export const SensorCard: React.FC<SensorCardProps> = ({
	steps,
}: SensorCardProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "carbonSavingsDetails.components.sensorCard",
	})

	const tableConfig = useSortableTable<CycleStepView>(
		[
			{
				id: "name",
				label: t("step"),
				render: ({ name, timestamp }) => (
					<Label title={formatTimestamp(timestamp)} inverted>
						{name}
					</Label>
				),
			},
			{
				id: "sensorId",
				label: t("sensorId"),
				render: ({ sensorId }) => <TextRegular>{sensorId}</TextRegular>,
			},
			{
				id: "totalSavings",
				label: t("lco2Measured"),
				valueClassName: "text-right max-sm:text-left",
				headerClassName: "text-right max-sm:text-left",
				render: ({ totalSavings }) => (
					<TextMedium>
						{formatNumberWithUnit(totalSavings)}
					</TextMedium>
				),
			},
		],
		undefined,
		{
			prefix: "sensor-row",
			postfixFieldName: nameof<CycleStepView>("name"),
		},
	)

	return (
		<RoundedBox className="p-4" data-cy="sensor-data">
			<CardHeader className="ml-2" data-cy="title">
				{t("title")}
			</CardHeader>
			<Table
				data-cy="sensor-table"
				data={steps}
				config={tableConfig}
				breakpoint="sm"
			/>
		</RoundedBox>
	)
}
