import { Cycle, CycleStatus, PermissionType } from "@carbonbank/api"
import { useAuthStore } from "@carbonbank/store"
import { formatNumberWithUnit, formatTimestamp } from "@carbonbank/utils"
import { nameof } from "@shared/lib/utility-types/table"
import {
	Button,
	Icon,
	Label,
	Table,
	TextRegular,
	icon,
	useSortableTable,
} from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { getAssureSavingDetailsUrl } from "../../carbonSavingsRoutes"
import { CycleStatusPill } from "../../components"

interface SavingsTableProps {
	data: Cycle[]
	onAssure: (cycle: Cycle) => void
}

export const SavingsTable: React.FC<SavingsTableProps> = ({
	data,
	onAssure,
}) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "carbonSavings.components.savingsTable",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})

	const [userPermissions] = useAuthStore(state => [state.userPermissions])

	const tableConfig = useSortableTable<(typeof data)[0]>(
		[
			{
				id: "cycle",
				label: t("cycleId"),
				render: ({ cycle }) => <TextRegular>#{cycle}</TextRegular>,
			},
			{
				id: "shipmentDate",
				label: t("cycleDate"),
				render: ({ shipmentDate }) => formatTimestamp(shipmentDate),
			},
			{
				id: "plant",
				label: t("plant"),
				showBreakpoint: "lg",
			},
			{
				id: "totalSavings",
				label: t("pumped"),
				valueClassName: "text-left sm:text-right",
				headerClassName: "text-right",
				render: ({ totalSavings, co2PumpedIntoStorageDate }) =>
					totalSavings ? (
						<Label
							title={formatTimestamp(co2PumpedIntoStorageDate!)}
							inverted
						>
							{formatNumberWithUnit(totalSavings)}
						</Label>
					) : (
						<>-</>
					),
			},
			{
				id: "buffer",
				label: t("buffer"),
				valueClassName: "text-left md:text-right",
				headerClassName: "text-right",
				showBreakpoint: "lg",
				render: ({
					totalSavings,
					buffer,
					assuranceTimestamp,
					status,
				}) => {
					const isAssuredColumnValuesReady =
						status === CycleStatus.Assured && totalSavings && buffer

					const assuredColumnValue = isAssuredColumnValuesReady ? (
						<Label
							title={formatTimestamp(assuranceTimestamp!)}
							inverted
						>
							{formatNumberWithUnit(totalSavings)}{" "}
							<span className="text-nowrap">
								({formatNumberWithUnit(buffer)})
							</span>
						</Label>
					) : (
						<>-</>
					)

					return assuredColumnValue
				},
			},
			{
				id: "status",
				label: t("status"),
				render: ({ status }) => <CycleStatusPill status={status} />,
			},
			{
				label: tCommon("actions"),
				valueClassName: "text-left md:text-right",
				headerClassName: "text-left md:text-right",
				sortable: false,
				render: row => {
					const isAssureBtnDislayed =
						[
							CycleStatus.New,
							CycleStatus.RequireMoreInformation,
						].includes(row.status) &&
						userPermissions?.some(
							x =>
								x.permissionType ===
								PermissionType.ManageSavings,
						)

					return (
						<>
							<Link
								to={getAssureSavingDetailsUrl(row.cycle)}
								className="inline-block lg:hidden"
							>
								<Icon path={icon.mdiChevronRight} />
							</Link>
							<div className="hidden lg:flex gap-x-3 justify-end">
								<Link to={getAssureSavingDetailsUrl(row.cycle)}>
									<Button
										variant="secondary"
										data-cy="details-button"
									>
										{tCommon("details")}
									</Button>
								</Link>
								{isAssureBtnDislayed && (
									<Button
										data-cy="assure-button"
										variant="primary"
										onClick={() => onAssure(row)}
									>
										{tCommon("assure")}
									</Button>
								)}
							</div>
						</>
					)
				},
			},
		],
		undefined,
		{
			prefix: "savings-item",
			postfixFieldName: nameof<Cycle>("cycle"),
		},
		{ enableLocalSorting: true },
	)

	return (
		<Table
			data={data}
			config={tableConfig}
			data-cy="savings-list-table"
			breakpoint="sm"
		/>
	)
}
