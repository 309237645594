import { DeadOutboxMessages } from "@features/dead-outbox-messages"
import { useLoadingStore } from "@shared/stores"

export const OutboxPage = () => {
	const PAGE_NAME = "outbox"
	const isLoading = useLoadingStore(state => state.isPageLoading(PAGE_NAME))
	return (
		<div className="p-6">
			<DeadOutboxMessages pageName={PAGE_NAME} isLoading={isLoading} />
		</div>
	)
}
