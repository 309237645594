import { certificatesApi, materialsApi } from "@shared/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import {
	CertificateDetailsFormInputs,
	CertificateDetailsFormState,
	CertificateType,
	SelectionOfCertificateFormInputs,
	SelectionOfCertificateTypeFormState,
	SelectionOfMaterialFormInputs,
	SelectionOfMaterialFormState,
} from "./types"

type State = {
	materialDescription?: string
	materialNumber?: string
	contractNumber?: string
	selectionOfCertificateTypeFormInputs?: SelectionOfCertificateFormInputs
	selectionOfMaterialFormInputs?: SelectionOfMaterialFormInputs
	certificateDetailsFormInputs?: CertificateDetailsFormInputs
	selectionOfCertificateTypeFormState?: SelectionOfCertificateTypeFormState
	selectionOfMaterialFormState?: SelectionOfMaterialFormState
	certificateDetailsFormState?: CertificateDetailsFormState
	createdProjectId?: string
}

type Actions = {
	fetchDefaultMaterial: (
		contractNumber: string,
		materialNumber: string,
	) => Promise<void>
	setDefaultMaterial: (
		materialDescription: string,
		materialNumber: string,
		contractNumber: string,
	) => void
	setSelectionOfCertificateTypeFormInputs: (
		formInputs: SelectionOfCertificateFormInputs,
	) => void
	setSelectionOfMaterialFormInputs: (
		formInputs: SelectionOfMaterialFormInputs,
	) => void
	setCertificateDetailsFormInputs: (
		formInputs: CertificateDetailsFormInputs,
	) => void
	setSelectionOfCertificateTypeFormState: (
		formInputs: SelectionOfCertificateFormInputs,
	) => void
	setSelectionOfMaterialFormState: (
		formInputs: SelectionOfMaterialFormInputs,
	) => void
	setCertificateDetailsFormState: (
		formInputs: CertificateDetailsFormInputs,
	) => void
	submitCertificate: () => void
	resetCertificateData: () => void
}

export const useCertificateCreationFormStore = create<State & Actions>()(
	immer((set, get) => ({
		materialDescription: undefined,
		materialNumber: undefined,
		contractNumber: undefined,

		selectionOfCertificateTypeFormInputs: undefined,
		selectionOfMaterialFormInputs: undefined,
		certificateDetailsFormInputs: undefined,

		selectionOfCertificateTypeFormState: undefined,
		selectionOfMaterialFormState: undefined,
		certificateDetailsFormState: undefined,

		createdProjectId: undefined,

		fetchDefaultMaterial: async (
			contractNumber: string,
			materialNumber: string,
		) => {
			try {
				const material = await materialsApi.getById(
					contractNumber,
					materialNumber,
				)

				set(state => {
					state.materialDescription = material.description
					state.materialNumber = material.itemNumber
					state.contractNumber = material.contract.number
				})
			} catch {
				spawnErrorToast(
					i18next.t(
						"shared:useCertificateCreationFormStore.errorMessages.fetchDefaultMaterial",
					),
				)
			}
		},
		setDefaultMaterial: (
			materialDescription: string,
			materialNumber: string,
			contractNumber: string,
		) =>
			set(state => {
				state.materialDescription = materialDescription
				state.materialNumber = materialNumber
				state.contractNumber = contractNumber
			}),

		setSelectionOfCertificateTypeFormInputs: (
			formInputs: SelectionOfCertificateFormInputs,
		) =>
			set(state => {
				state.selectionOfCertificateTypeFormInputs = formInputs
			}),
		setSelectionOfMaterialFormInputs: (
			formInputs: SelectionOfMaterialFormInputs,
		) =>
			set(state => {
				state.selectionOfMaterialFormInputs = formInputs
			}),
		setCertificateDetailsFormInputs: (
			formInputs: CertificateDetailsFormInputs,
		) =>
			set(state => {
				state.certificateDetailsFormInputs = formInputs
			}),

		setSelectionOfCertificateTypeFormState: (
			formInputs: SelectionOfCertificateFormInputs,
		) => {
			const project = formInputs.project || formInputs.newProject

			if (!project) {
				return
			}

			set(state => {
				state.selectionOfCertificateTypeFormState = {
					certificateType: formInputs.certificateType,
					projectId: formInputs.projectId,
					project: project,
				}
			})
		},
		setSelectionOfMaterialFormState: (
			formInputs: SelectionOfMaterialFormInputs,
		) =>
			set(state => {
				state.selectionOfMaterialFormState = {
					materialNumber: formInputs.material,
					contractNumber: formInputs.contractNumber,
					materialDescription: formInputs.materialDescription,
					customerId: formInputs.customerId,
					quantity: formInputs.quantity,
					totalQuantity: formInputs.totalQuantity,
					savings: formInputs.savings,
					unit: formInputs.unit,
				}
			}),
		setCertificateDetailsFormState: (
			formInputs: CertificateDetailsFormInputs,
		) => {
			const shouldNotSetState =
				!formInputs.country ||
				!formInputs.project ||
				!formInputs.companyName ||
				!formInputs.street ||
				!formInputs.zipCode ||
				!formInputs.city

			if (shouldNotSetState) {
				return
			}

			set(state => {
				state.certificateDetailsFormState = {
					project: formInputs.project!,
					companyName: formInputs.companyName!,
					street: formInputs.street!,
					zipCode: formInputs.zipCode!,
					city: formInputs.city!,
					country: formInputs.country!,
				}
			})
		},
		submitCertificate: async () => {
			try {
				const certificateCreationState = get()

				const shouldNotSubmit =
					!certificateCreationState.certificateDetailsFormState ||
					!certificateCreationState.selectionOfMaterialFormState ||
					!certificateCreationState.selectionOfCertificateTypeFormState ||
					!certificateCreationState.materialNumber ||
					!certificateCreationState.contractNumber

				if (shouldNotSubmit) {
					return
				}

				const response = await certificatesApi.create({
					projectReference: {
						projectReferenceId:
							certificateCreationState
								.selectionOfCertificateTypeFormState
								?.projectId || undefined,
						customerId:
							certificateCreationState
								.selectionOfMaterialFormState!.customerId,
						reference:
							certificateCreationState
								.certificateDetailsFormState!.project,
						isInternal:
							certificateCreationState
								.selectionOfCertificateTypeFormState!
								.certificateType ===
							CertificateType.InternalProject,
					},
					certificateHolder: {
						companyName:
							certificateCreationState
								.certificateDetailsFormState!.companyName,
						street: certificateCreationState
							.certificateDetailsFormState!.street,
						postalCode:
							certificateCreationState
								.certificateDetailsFormState!.zipCode,
						city: certificateCreationState
							.certificateDetailsFormState!.city,
						country:
							certificateCreationState
								.certificateDetailsFormState!.country,
					},
					contractNumber: certificateCreationState.contractNumber!,
					materialNumber: certificateCreationState.materialNumber!,
					deliveredQuantity:
						certificateCreationState.selectionOfMaterialFormState!
							.quantity,
					deliveredUnit:
						certificateCreationState.selectionOfMaterialFormState!
							.unit,
				})

				set(state => {
					state.createdProjectId = response.projectId
				})
			} catch {
				spawnErrorToast(
					i18next.t(
						"shared:useCertificateCreationFormStore.errorMessages.submitCertificate",
					),
				)
			}
		},
		resetCertificateData: () =>
			set(state => {
				state.materialDescription = undefined
				state.materialNumber = undefined
				state.selectionOfCertificateTypeFormInputs = undefined
				state.selectionOfMaterialFormInputs = undefined
				state.certificateDetailsFormInputs = undefined
				state.selectionOfCertificateTypeFormState = undefined
				state.selectionOfMaterialFormState = undefined
				state.certificateDetailsFormState = undefined
				state.createdProjectId = undefined
			}),
	})),
)
