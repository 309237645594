import { formatTimestamp, getTransactionHashScanLink } from "@carbonbank/utils"
import { nameof } from "@shared/lib/utility-types/table"
import { ProjectDetailCertificate } from "@shared/models"
import {
	Button,
	ExternalLink,
	Icon,
	icon,
	useSortableTable,
} from "@sustainability/fundamental"
import { TFunction } from "i18next"

const CAMMON_VALUE_COLUMNS_CLASS_NAME =
	"whitespace-nowrap overflow-hidden text-ellipsis"

export const useTable = (t: TFunction<"features", string>) =>
	useSortableTable<ProjectDetailCertificate>(
		[
			{
				id: "materialDescription",
				label: t("material"),
				sortable: false,
				render: ({ materialDescription }) => (
					<div
						data-cy="material-description"
						className={CAMMON_VALUE_COLUMNS_CLASS_NAME}
					>
						{materialDescription}
					</div>
				),
				valueClassName: "lg:w-[132px] xl:w-[216px] ",
				headerClassName: "lg:w-[132px] xl:w-[216px]",
			},
			{
				id: "createdOn",
				label: t("dateOfIssue"),
				sortable: false,
				render: ({ createdOn }) => (
					<div
						data-cy="created-on"
						className={CAMMON_VALUE_COLUMNS_CLASS_NAME}
					>
						{formatTimestamp(createdOn.toString())}
					</div>
				),
				valueClassName: "lg:w-[141px] xl:w-[224px]",
				headerClassName: "lg:w-[141px] xl:w-[224px]",
			},
			{
				id: "quantity",
				label: t("quantity"),
				sortable: false,
				render: ({ quantity }) => (
					<div
						data-cy="quantity"
						className={CAMMON_VALUE_COLUMNS_CLASS_NAME}
					>
						{quantity.amount}
					</div>
				),
				valueClassName: "lg:text-right lg:w-[141px] xl:w-[224px]",
				headerClassName: "lg:text-right lg:w-[141px] xl:w-[224px]",
			},
			{
				id: "totalSavings",
				label: t("carbonSavings"),
				sortable: false,
				render: ({ totalSavings }) => (
					<div
						data-cy="total-savings"
						className={CAMMON_VALUE_COLUMNS_CLASS_NAME}
					>
						{totalSavings.amount}
					</div>
				),
				valueClassName: "lg:text-right lg:w-[141px] xl:w-[224px]",
				headerClassName: "lg:text-right lg:w-[141px] xl:w-[224px]",
			},
			{
				id: "transactionId",
				label: t("transactionNumber.title"),
				sortable: false,
				render: ({ transactionId }) => (
					<div
						className={`lg:w-[141px] ${CAMMON_VALUE_COLUMNS_CLASS_NAME}`}
					>
						{/* TODO Do I need transactionLink */}
						{/* TODO Add status */}
						{transactionId && (
							<ExternalLink
								href={getTransactionHashScanLink(transactionId)}
							>
								{transactionId}
							</ExternalLink>
						)}
					</div>
				),
				valueClassName: "lg:w-[141px] xl:w-[224px]",
				headerClassName: "lg:w-[141px] xl:w-[224px]",
			},
			{
				label: t("actions.title"),
				sortable: false,
				render: ({ transactionId }) => (
					<div className="flex gap-[12px] justify-end items-center lg:flex-row xs:flex-col">
						<ExternalLink
							href={getTransactionHashScanLink(transactionId)}
						>
							<Button variant="tertiary" className="no-underline">
								<div className="flex items-center gap-2">
									<Icon path={icon.mdiOpenInNew} />
									{t("actions.show")}
								</div>
							</Button>
						</ExternalLink>
						{transactionId && (
							<Button
								className="lg:w-[110px] xs:w-full"
								variant="negative"
								icon={icon.mdiDelete}
							>
								{t("actions.delete")}
							</Button>
						)}
					</div>
				),
				valueClassName: "lg:text-right lg:w-[256px]",
				headerClassName: "lg:text-right lg:w-[256px]",
			},
		],
		[t],
		{
			prefix: "project-certificate-list-row",
			postfixFieldName: nameof<ProjectDetailCertificate>(
				"materialDescription",
			),
		},
		{ enableLocalSorting: true },
	)
