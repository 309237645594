import { InternalAxiosRequestConfig } from "axios"

import { getFreshToken } from "./utils"

export const tokenInterceptor = async (
	config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
	const token = await getFreshToken()
	const authorizationHeader = `Bearer ${token?.accessToken}`

	config.headers.Authorization = authorizationHeader

	return config
}
