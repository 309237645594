import { outboxMessagesApi } from "@shared/api"
import {
	PaginationActions,
	PaginationState,
	usePaginationStore,
} from "@shared/stores"
import { spawnErrorToast } from "@sustainability/fundamental"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { formatOutboxMessages } from "../utils"
import { OutboxMessage } from "./types"

type State = PaginationState & {
	messages: OutboxMessage[]
}

type Actions = PaginationActions & {
	fetchOutboxMessages: () => Promise<void>
	retryOutboxMessage: (id: string) => Promise<void>
	resetStore: () => void
}

export const useOutboxMessagesStore = create<State & Actions>()(
	immer((set, get, api) => ({
		...usePaginationStore(set, get, api),

		messages: [],

		fetchOutboxMessages: async () => {
			try {
				const response = await outboxMessagesApi.get(
					get().paginationRequest,
				)

				const messages = formatOutboxMessages(response.messages)

				if (response.page === 0) {
					set(state => {
						state.messages = messages
					})
				} else {
					set(state => {
						state.messages.push(...messages)
					})
				}

				get().updatePagination(response, get().messages.length)
			} catch {
				spawnErrorToast(
					i18next.t("features:outboxMessages.errors.fetch"),
				)
			}
		},
		retryOutboxMessage: async (id: string) => {
			try {
				await outboxMessagesApi.retry(id)
			} catch {
				spawnErrorToast(
					i18next.t("features:outboxMessages.errors.retry", {
						id,
					}),
				)
			}
		},
		resetStore: () => {
			set(state => {
				state.messages = []
			})
			get().resetPagination()
		},
	})),
)
