import { i18n as fundamentalI18nInstance } from "@sustainability/fundamental"
import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import { ModuleKeys as CarbonSavingsModuleKeys } from "../../pages/carbon-savings/i18n"
import { ModuleKeys as CommonModuleKeys } from "../../pages/common/i18n"
import { ModuleKeys as CustomersModuleKeys } from "../../pages/customer/i18n"
import { ModuleKeys as MicrositeModuleKeys } from "../../pages/microsite/i18n"
import { resources } from "./translations"

const namespaces = [
	"cb",
	"cs",
	"contracts",
	"transactions",
	"microsite",
	"customer",
] as const
export const defaultNS: (typeof namespaces)[number] = "cb"

export const supportedLanguages = ["en-GB", "de-DE"] as const
export type SupportedLanguages = (typeof supportedLanguages)[number]

i18next.use(initReactI18next).init({
	resources,
	lng: "en-GB",
	fallbackLng: "en-GB",
	supportedLngs: supportedLanguages,
	ns: namespaces,
	defaultNS,
	interpolation: {
		escapeValue: false, // react already safes from xss
	},
	react: {
		useSuspense: true,
	},
})

i18next.on("languageChanged", lang => {
	fundamentalI18nInstance.changeLanguage(lang)
})

export type TranslationKeys =
	| CarbonSavingsModuleKeys
	| CommonModuleKeys
	| CustomersModuleKeys
	| MicrositeModuleKeys

export default i18next

export * from "./utils"
