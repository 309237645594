import { RouteObject } from "react-router-dom"

import { Layout } from "./Layout"

const baseUrl = "/"
const authBaseUrl = "/auth"
const logoutUrl = "callback/logout"

export const commonRoutes: RouteObject[] = [
	{
		path: baseUrl,
		element: <Layout />,
	},
	{
		path: authBaseUrl,
		element: <Layout />,
		children: [
			{
				path: logoutUrl,
				element: <Layout />,
			},
		],
	},
]

export const getLandingUrl = () => baseUrl
