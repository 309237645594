import { PermissionType, RoleType } from "@carbonbank/api"
import { AccessDeniedPage } from "@pages/technical/access-denied"
import {
	certificateCreation,
	materialDetails,
	materials,
	projectDetail,
	projects,
} from "@shared/routes"
import { RouteObject } from "react-router-dom"

const commonRoutePaths = {
	home: "/",
	notFound: "/*",

	//TODO: must be moved to routePaths when specific permissions are defined
	customer: "/customer/:id",

	microSite: "/microsite/:id",

	transactions: "/transactions",
	transactionDetails: "/transactions/:id",

	accessDenied: "/access-denied",
	serviceUnavailable: "/service-unavailable",
	unauthorized: "/unauthorized",
}

const routePaths = {
	outbox: "/admin/outbox",

	yearlyAssurance: "/carbon-savings",
	yearlyAssuranceReport: "/carbon-savings/:year/:location/report",
	carbonSavings: "/carbon-savings/assure-savings",
	carbonSavingsDetails: "/carbon-savings/assure-savings/:id",
	productAllocation: "/carbon-savings/product-allocation",
	productAllocationDetails: "/carbon-savings/product-allocation/:id",

	contractValidation: "/customers/contracts/validation",

	materials: materials,
	materialDetails: `${materials}/${materialDetails}`,

	projects: projects,
	projectDetail: `${projects}/${projectDetail}`,
	certificateCreation: `${materials}}/${certificateCreation}`,
	selectionOfCertificateTypeForm: `${materials}/${certificateCreation}/selection-of-certificate-type`,
	selectionOfMaterialForm: `${materials}/${certificateCreation}/selection-of-material`,
	certificateDetailsForm: `${materials}/${certificateCreation}/certificate-details`,
	certificateCreationWithMaterial: `${materials}/:contractNumber/:materialNumber/${certificateCreation}`,
	selectionOfCertificateTypeFormWithMaterial: `${materials}/:contractNumber/:materialNumber/${certificateCreation}/selection-of-certificate-type`,
	selectionOfMaterialFormWithMaterial: `${materials}/:contractNumber/:materialNumber/${certificateCreation}/selection-of-material`,
	certificateDetailsFormWithMaterial: `${materials}/:contractNumber/:materialNumber/${certificateCreation}/certificate-details`,
}

const permissionTypeToRoutes: Record<PermissionType, string[]> = {
	[PermissionType.ViewContracts]: [
		routePaths.materials,
		routePaths.materialDetails,
		routePaths.projects,
		routePaths.projectDetail,
	],
	[PermissionType.ViewCertificates]: [
		routePaths.materials,
		routePaths.materialDetails,
		routePaths.projects,
		routePaths.projectDetail,
	],
	[PermissionType.ManageCertiticates]: [
		routePaths.materials,
		routePaths.materialDetails,
		routePaths.projects,
		routePaths.projectDetail,
		routePaths.certificateCreation,
		routePaths.selectionOfCertificateTypeForm,
		routePaths.selectionOfMaterialForm,
		routePaths.certificateDetailsForm,
		routePaths.certificateCreationWithMaterial,
		routePaths.selectionOfCertificateTypeFormWithMaterial,
		routePaths.selectionOfMaterialFormWithMaterial,
		routePaths.certificateDetailsFormWithMaterial,
	],
	[PermissionType.ViewTransactions]: [
		routePaths.yearlyAssurance,
		routePaths.productAllocation,
		routePaths.productAllocationDetails,
		routePaths.outbox,
	],
	[PermissionType.ManageTransactions]: [
		routePaths.yearlyAssurance,
		routePaths.yearlyAssuranceReport,
		routePaths.productAllocation,
		routePaths.productAllocationDetails,
	],
	[PermissionType.ViewSavings]: [
		routePaths.carbonSavings,
		routePaths.carbonSavingsDetails,
	],
	[PermissionType.ManageSavings]: [
		routePaths.carbonSavings,
		routePaths.carbonSavingsDetails,
	],
	[PermissionType.ManageOutbox]: [routePaths.outbox],
}

const roleTypeToRoutes: Record<RoleType, string> = {
	[RoleType.CarbonBankAdmin]: commonRoutePaths.home,
	[RoleType.MrvAnnualAssurer]: routePaths.yearlyAssurance,
	[RoleType.CarbonBankCustomer]: routePaths.materials,
	[RoleType.MrvDepositAssurer]: routePaths.carbonSavings,
}

const isCommonRoutePath = (path: string) => {
	return Object.values(commonRoutePaths).includes(path)
}

const getFullRoutePath = (route: RouteObject, routePathPrefix: string) => {
	const routePath = !route.index && route.path ? route.path : ""
	const fullRoutePathSeparator =
		!routePath || routePathPrefix === commonRoutePaths.home ? "" : "/"
	const fullRoutePath = routePathPrefix
		? `${routePathPrefix}${fullRoutePathSeparator}${routePath}`
		: routePath

	return fullRoutePath
}

const shouldSkipRoute = (
	route: RouteObject,
	routePathPrefix: string,
	fullRoutePath: string,
) => {
	return (
		!routePathPrefix ||
		(!route.path && !route.index) ||
		!route.element ||
		isCommonRoutePath(fullRoutePath)
	)
}

export const markAllRoutesAsUnavailable = (
	routes: RouteObject[],
	routePathPrefix = "",
) => {
	routes.forEach(route => {
		const fullRoutePath = getFullRoutePath(route, routePathPrefix)
		const isSkipRoute = shouldSkipRoute(
			route,
			routePathPrefix,
			fullRoutePath,
		)
		const childrenRoutes = route.children as RouteObject[]

		if (childrenRoutes) {
			markAllRoutesAsUnavailable(childrenRoutes, fullRoutePath)
		}

		if (isSkipRoute) {
			return
		}

		route.element = <AccessDeniedPage />
	})
}

const getAvailableRoutesByPermissions = (permissions: PermissionType[]) => {
	const availableRoutes = new Set<string>()

	permissions.forEach(permission => {
		const routes = permissionTypeToRoutes[permission]
		routes?.forEach(route => availableRoutes.add(route))
	})

	return Array.from(availableRoutes)
}

const markUnavailableRoutes = (
	routes: RouteObject[],
	availableRoutes: string[],
	routePathPrefix = "",
) => {
	routes.forEach(route => {
		const fullRoutePath = getFullRoutePath(route, routePathPrefix)
		const isSkipRoute = shouldSkipRoute(
			route,
			routePathPrefix,
			fullRoutePath,
		)
		const childrenRoutes = route.children as RouteObject[]

		if (childrenRoutes) {
			markUnavailableRoutes(
				childrenRoutes,
				availableRoutes,
				fullRoutePath,
			)
		}

		if (isSkipRoute) {
			return
		}

		const isPathUnavailable = !availableRoutes.includes(fullRoutePath)

		if (isPathUnavailable) {
			route.element = <AccessDeniedPage />
		}
	})
}

export const markUnavailableRoutesByPermissionTypes = (
	routes: RouteObject[],
	permissions: PermissionType[],
) => {
	if (permissions.length === 0) {
		markAllRoutesAsUnavailable(routes)
		return
	}

	const availableRoutes = getAvailableRoutesByPermissions(permissions)

	markUnavailableRoutes(routes, availableRoutes)
}

export const getDefaultRouteByRoleTypes = (
	roleTypes: RoleType[] | undefined,
) => {
	if (!roleTypes || roleTypes.length === 0) {
		return commonRoutePaths.transactions
	}

	const isCarbonBankAdmin = roleTypes.includes(RoleType.CarbonBankAdmin)

	if (isCarbonBankAdmin) {
		return commonRoutePaths.home
	}

	const fullCarbonBankAssurerRoles = [
		RoleType.MrvAnnualAssurer,
		RoleType.MrvDepositAssurer,
	]
	const isCarbonBankAssurer = fullCarbonBankAssurerRoles.every(x =>
		roleTypes.includes(x),
	)

	if (isCarbonBankAssurer) {
		return routePaths.carbonSavings
	}

	const supportedRoleType = Object.keys(roleTypeToRoutes).find(roleType =>
		roleTypes.includes(roleType as RoleType),
	)

	return (
		roleTypeToRoutes[supportedRoleType as RoleType] ??
		commonRoutePaths.transactions
	)
}

type StartPageNames =
	| "home"
	| "transactions"
	| "carbonSavings"
	| "yearlyAssurance"
	| "manageCertificates"

export interface IStartPageInfo {
	name: StartPageNames
	path: string
}

export const getStartPageInfoByRoleTypes = (
	roleTypes: RoleType[] | undefined,
): IStartPageInfo => {
	const transactionPageInfo: IStartPageInfo = {
		name: "transactions",
		path: commonRoutePaths.transactions,
	}

	if (!roleTypes?.length) {
		return transactionPageInfo
	}

	const roleToPageMap = new Map<RoleType, StartPageNames>([
		[RoleType.CarbonBankAdmin, "home"],
		[RoleType.MrvDepositAssurer, "carbonSavings"],
		[RoleType.MrvAnnualAssurer, "yearlyAssurance"],
		[RoleType.CarbonBankCustomer, "manageCertificates"],
	])

	for (const roleToPageItem of roleToPageMap) {
		if (roleTypes.includes(roleToPageItem[0])) {
			return {
				name: roleToPageItem[1],
				path: roleTypeToRoutes[roleToPageItem[0]],
			}
		}
	}

	return transactionPageInfo
}

export const isHomeRoute = (path: string) => {
	return path === commonRoutePaths.home || path === "/auth"
}
