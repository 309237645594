import activityHistory from "@features/activity-history/assets/lang"
import certificatesDetailsForm from "@features/certificate-details-form/assets/lang"
import certificatesList from "@features/certificates-list/assets/lang"
import deadOutboxMessages from "@features/dead-outbox-messages/assets/lang"
import invoicesList from "@features/invoices-list/assets/lang"
import materialDetailsHeader from "@features/material-details-header/assets/lang"
import materialsList from "@features/materials-list/assets/lang"
import projectDetailCeritificates from "@features/project-detail-certificates/assets/lang"
import projectDetailHeader from "@features/project-detail-header/assets/lang"
import projectsList from "@features/projects-list/assets/lang"
import selectionOfCertificateTypeForm from "@features/selection-of-certificate-type-form/assets/lang"
import selectionOfMaterialForm from "@features/selection-of-material-form/assets/lang"
import accessDenied from "@pages/technical/access-denied/assets/lang"
import notFound from "@pages/technical/not-found/assets/lang"
import serviceUnavailable from "@pages/technical/service-unavailable/assets/lang"
import unauthorized from "@pages/technical/unauthorized/assets/lang"
import certificateCreation from "@pages/transfer/certificate-creation/assets/lang"
import manageCertificates from "@pages/transfer/manage-certificates/assets/lang"
import materialDetails from "@pages/transfer/material-details/assets/lang"
import projectDetail from "@pages/transfer/project-detail/assets/lang"
import shared from "@shared/assets/lang"
import projectCeritificates from "@shared/stores/project-detail-certificates/assets/lang"
import dnvCarbonSavingsCard from "@shared/ui/dnv-carbon-savings-card/assets/lang"
import formWithSummary from "@shared/ui/form-with-summary/assets/lang"
import { ResourceLanguage } from "i18next"

import { SupportedLanguages } from "."
import { globalTranslations } from "../../assets"
import { carbonSavingsTranslations } from "../../pages/carbon-savings/assets"
import { contractsTranslations } from "../../pages/contracts/assets"
import { customer } from "../../pages/customer/assets"
import { microsite } from "../../pages/microsite/assets"
import { transactions } from "../../pages/transactions/assets"

// import { outbox } from "../routes"

export const resources: Record<SupportedLanguages, ResourceLanguage> = {
	"en-GB": {
		cb: globalTranslations.en,
		cs: carbonSavingsTranslations.en,
		contracts: contractsTranslations.en,
		transactions: transactions.en,
		microsite: microsite.en,
		customer: customer.en,
		features: {
			materialsList: materialsList.en,
			projectsList: projectsList.en,
			invoicesList: invoicesList.en,
			certificatesList: certificatesList.en,
			materialDetailsHeader: materialDetailsHeader.en,
			projectDetailHeader: projectDetailHeader.en,
			projectDetailCeritificates: projectDetailCeritificates.en,
			activityHistory: activityHistory.en,
			dnvCarbonSavingsCard: dnvCarbonSavingsCard.en,
			selectionOfMaterialForm: selectionOfMaterialForm.en,
			selectionOfCertificateTypeForm: selectionOfCertificateTypeForm.en,
			certificatesDetailsForm: certificatesDetailsForm.en,
			deadOutboxMessages: deadOutboxMessages.en,
		},
		pages: {
			manageCertificates: manageCertificates.en,
			materialDetails: materialDetails.en,
			projectDetail: projectDetail.en,
			certificateCreation: certificateCreation.en,
			unauthorized: unauthorized.en,
			accessDenied: accessDenied.en,
			serviceUnavailable: serviceUnavailable.en,
			notFound: notFound.en,
		},
		shared: {
			...shared.en,
			formWithSummary: formWithSummary.en,
			dnvCarbonSavingsCard: dnvCarbonSavingsCard.en,
			projectCertificates: projectCeritificates.en,
		},
	},
	"de-DE": {
		cb: globalTranslations.de,
		cs: carbonSavingsTranslations.de,
		contracts: contractsTranslations.de,
		transactions: transactions.de,
		microsite: microsite.de,
		customer: customer.de,
		features: {
			materialsList: materialsList.de,
			projectsList: projectsList.de,
			invoicesList: invoicesList.de,
			certificatesList: certificatesList.de,
			materialDetailsHeader: materialDetailsHeader.de,
			projectDetailHeader: projectDetailHeader.de,
			projectDetailCeritificates: projectDetailCeritificates.en,
			activityHistory: activityHistory.de,
			dnvCarbonSavingsCard: dnvCarbonSavingsCard.de,
			selectionOfMaterialForm: selectionOfMaterialForm.de,
			selectionOfCertificateTypeForm: selectionOfCertificateTypeForm.de,
			certificatesDetailsForm: certificatesDetailsForm.de,
			deadOutboxMessages: deadOutboxMessages.de,
		},
		pages: {
			manageCertificates: manageCertificates.de,
			materialDetails: materialDetails.de,
			projectDetail: projectDetail.de,
			certificateCreation: certificateCreation.de,
			unauthorized: unauthorized.de,
			accessDenied: accessDenied.de,
			serviceUnavailable: serviceUnavailable.de,
			notFound: notFound.de,
		},
		shared: {
			...shared.de,
			formWithSummary: formWithSummary.de,
			dnvCarbonSavingsCard: dnvCarbonSavingsCard.de,
			projectCertificates: projectCeritificates.de,
		},
	},
}
