import { CycleStatus } from "@carbonbank/api"
import { ModuleKeys as CommonModuleKeys } from "@carbonbank/pages/common"

export const cycleStatusToLabelMap: Record<CycleStatus, CommonModuleKeys> = {
	[CycleStatus.New]: "common.cycleStatus.new",
	[CycleStatus.AwaitingAssurance]: "common.cycleStatus.awaitingAssurance",
	[CycleStatus.Rejected]: "common.cycleStatus.rejected",
	[CycleStatus.Assured]: "common.cycleStatus.assured",
	[CycleStatus.RequireMoreInformation]: "common.cycleStatus.inValidation",
	[CycleStatus.Failed]: "common.cycleStatus.failed",
}
