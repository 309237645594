import { useLoadingStore } from "@shared/stores"
import { FeatureToLoading } from "@shared/stores/loading/types"
import { RoundedBox } from "@shared/ui"
import { Button, CardHeader, Loading, Table } from "@sustainability/fundamental"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useOutboxMessagesStore } from "../model"
import { useOutboxMessagesTableConfig } from "./dead-outbox-messages-table/table"

export type DeadOutboxMessagesProps = {
	pageName: string
	isLoading: boolean
}

export const DeadOutboxMessages: React.FC<DeadOutboxMessagesProps> = ({
	pageName,
	isLoading,
}) => {
	const FEATURE_NAME = "outboxMessages"
	const featureToLoading: FeatureToLoading = {
		page: pageName,
		feature: FEATURE_NAME,
	}

	const { t } = useTranslation("features", {
		keyPrefix: "deadOutboxMessages",
	})

	const { t: tTable } = useTranslation("features", {
		keyPrefix: "deadOutboxMessages.table",
	})

	const [addFeatureToLoading, removeFeatureFromLoading] = useLoadingStore(
		state => [state.addFeatureToLoading, state.removeFeatureFromLoading],
	)

	const [
		messages,
		canLoadMore,
		fetchMessages,
		retryOutboxMessage,
		resetStore,
	] = useOutboxMessagesStore(state => [
		state.messages,
		state.canLoadMore,
		state.fetchOutboxMessages,
		state.retryOutboxMessage,
		state.resetStore,
	])

	const fetchDeadMessages = async () => {
		addFeatureToLoading(featureToLoading)
		await fetchMessages()
		removeFeatureFromLoading(featureToLoading)
	}

	const handleRetry = async (id: string) => {
		addFeatureToLoading(featureToLoading)
		await retryOutboxMessage(id)
		resetStore()
		await fetchDeadMessages()
		removeFeatureFromLoading(featureToLoading)
	}

	const handleCopyToClipboard = (message: string) => {
		navigator.clipboard.writeText(message)
	}

	const tableConfig = useOutboxMessagesTableConfig(
		tTable,
		handleRetry,
		handleCopyToClipboard,
	)

	useEffect(() => {
		fetchDeadMessages()

		return () => {
			resetStore()
		}
	}, [])

	return (
		<RoundedBox className="flex flex-col bg-white p-6">
			<CardHeader className="pl-4 lg:pl-3">{t("title")}</CardHeader>
			<div className="flex flex-col items-center gap-4">
				{isLoading ? (
					<Loading data-cy="outbox-messages-loading" size="large" />
				) : (
					<Table
						className="table-fixed"
						data-cy="outbox-messages-table"
						config={tableConfig}
						data={messages}
						breakpoint="md"
					/>
				)}
				{canLoadMore && (
					<Button
						data-cy="button-load-more"
						variant="tertiary"
						onClick={fetchDeadMessages}
					>
						{t("loadMore")}
					</Button>
				)}
			</div>
		</RoundedBox>
	)
}
