import { CentralizedContainerBox } from "@shared/ui"
import { spawnSuccessToast } from "@sustainability/fundamental"
import { ErrorActionCard } from "@widgets/error-action-card"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"

export const ServiceUnavailablePage: React.FC = () => {
	const [searchParams] = useSearchParams()
	const url = searchParams.get("backUrl")
	const errorMessage = searchParams.get("errorMessage")

	const { t } = useTranslation("pages", { keyPrefix: "serviceUnavailable" })

	const navigate = useNavigate()

	const handlePrimaryButtonClick = () => {
		if (!url) {
			return
		}

		navigate(url)
	}

	const handleSecondaryButtonClick = () => {
		const decodedErrorMessage = decodeURIComponent(errorMessage || "")
		navigator.clipboard.writeText(decodedErrorMessage)
		spawnSuccessToast(t("errorLogCopiedMessage"))
	}

	return (
		<CentralizedContainerBox data-cy="service-unavailable-page-container">
			<ErrorActionCard
				title={t("title")}
				description={t("description")}
				type="serviceUnavailable"
				primaryButtonTitle={t("primaryButtonTitle")}
				onPrimaryButtonClick={handlePrimaryButtonClick}
				secondaryButtonTitle={t("secondaryButtonTitle")}
				onSecondaryButtonClick={handleSecondaryButtonClick}
			/>
		</CentralizedContainerBox>
	)
}
