import { cycleApi } from "@carbonbank/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	totalCarbonCaptured?: number
}
type Actions = {
	fetchTotalCarbonCaptured: () => Promise<void>
}

export const useTransactionStore = create<State & Actions>()(
	immer(set => ({
		totalCarbonCaptured: undefined,
		fetchTotalCarbonCaptured: async () => {
			try {
				const deposit = await cycleApi.getTotalDeposits(
					new Date().getFullYear(),
				)

				set(state => {
					state.totalCarbonCaptured = deposit.sum
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch total deposit")
			}
		},
	})),
)
