import AccessDeniedImage from "@shared/assets/access-denied.svg"
import NotFoundImage from "@shared/assets/not-found.svg"
import ServiceUnavailableImage from "@shared/assets/service-unavailable.svg"
import UnauthorizedImage from "@shared/assets/unauthorized.svg"
import { Button, H1, icon, TextRegular } from "@sustainability/fundamental"

interface ErrorActionCardProps {
	title: string
	description: string
	type: "unauthorized" | "accessDenied" | "serviceUnavailable" | "notFound"
	primaryButtonTitle?: string
	onPrimaryButtonClick?: () => void
	secondaryButtonTitle?: string
	onSecondaryButtonClick?: () => void
}

export const ErrorActionCard: React.FC<ErrorActionCardProps> = ({
	title,
	description,
	type,
	primaryButtonTitle,
	onPrimaryButtonClick,
	secondaryButtonTitle,
	onSecondaryButtonClick,
}) => {
	const assetContent = {
		unauthorized: {
			image: {
				src: UnauthorizedImage,
				height: "235px",
			},
			primaryButtonIcon: icon.mdiArrowRight,
		},
		accessDenied: {
			image: {
				src: AccessDeniedImage,
				height: "190px",
			},
			secondaryButtonIcon: icon.mdiLogout,
		},
		serviceUnavailable: {
			image: {
				src: ServiceUnavailableImage,
				height: "160px",
			},
			primaryButtonIcon: icon.mdiRefresh,
			secondaryButtonIcon: icon.mdiContentCopy,
		},
		notFound: {
			image: {
				src: NotFoundImage,
				height: "175px",
			},
			secondaryButtonIcon: icon.mdiArrowRight,
		},
	}[type]

	return (
		<div
			data-cy={`error-action-card-container-${type}`}
			className="flex flex-col items-center gap-6 w-full max-w-[500px] mx-6 my-12 max-sm:mx-4 max-sm:my-6"
		>
			<img
				data-cy={`error-action-card-image-${type}`}
				src={assetContent.image.src}
				alt={type}
				className={`w-[24Opx] h-[${assetContent.image.height}px]`}
			/>
			<H1
				data-cy={`error-action-card-title-${type}`}
				className="text-center"
			>
				{title}
			</H1>
			<TextRegular
				data-cy={`error-action-card-description-${type}`}
				className="text-foreground_faded text-center"
			>
				{description}
			</TextRegular>
			<div
				data-cy={`error-action-card-button-container-${type}`}
				className="flex flex-wrap gap-3 max-sm:w-full max-sm:flex-col"
			>
				{primaryButtonTitle && (
					<Button
						data-cy={`error-action-card-primary-button-${type}`}
						type="button"
						variant="primary"
						icon={assetContent.primaryButtonIcon}
						onClick={onPrimaryButtonClick}
					>
						{primaryButtonTitle}
					</Button>
				)}
				{secondaryButtonTitle && (
					<Button
						data-cy={`error-action-card-secondary-button-${type}`}
						type="button"
						variant="secondary"
						icon={assetContent.secondaryButtonIcon}
						onClick={onSecondaryButtonClick}
					>
						{secondaryButtonTitle}
					</Button>
				)}
			</div>
		</div>
	)
}
