import {
	InvoiceAllocationItem,
	InvoiceCertificate,
	invoiceApi,
} from "@carbonbank/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { ProductAllocationFilterInput } from "./types"

type State = {
	invoiceList?: InvoiceAllocationItem[]
	invoiceCertificate?: InvoiceCertificate
}
type Actions = {
	resetStore: () => void
	fetchInvoiceList: (filters: ProductAllocationFilterInput) => Promise<void>
	fetchInvoiceCertificate: (id: string) => Promise<void>
	flagInvoice: (id: string, comment: string) => Promise<void>
	resolveInvoice: (id: string) => Promise<void>
}

export const useProductAllocationStore = create<State & Actions>()(
	immer(set => ({
		invoiceList: undefined,
		invoiceCertificate: undefined,

		resetStore: () => {
			set(state => {
				state.invoiceList = undefined
				state.invoiceCertificate = undefined
			})
		},
		fetchInvoiceList: async (filters: ProductAllocationFilterInput) => {
			try {
				const endDate = new Date(filters.timeframe![0])

				endDate.setUTCMonth(endDate.getUTCMonth() + 2)
				endDate.setUTCDate(0)

				const timeFrameStart = filters.timeframe![0]
				const timeFrameEnd = !filters.timeframe?.[1]
					? endDate.toISOString()
					: filters.timeframe![1]

				const response = await invoiceApi.getInvoiceList({
					location: filters.location,
					customerNumber: filters.customer,
					product: filters.product,
					timeFrameStart: timeFrameStart,
					timeFrameEnd: timeFrameEnd,
					flagged: filters.flagged === "flagged",
				})

				set(state => {
					state.invoiceList = response
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch transactions list")
			}
		},
		fetchInvoiceCertificate: async (id: string) => {
			try {
				set(state => {
					state.invoiceCertificate = undefined
				})

				const response = await invoiceApi.getInvoiceCertificate(id)

				set(state => {
					state.invoiceCertificate = response
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch transactions details")
			}
		},
		flagInvoice: async (id: string, comment: string) => {
			try {
				await invoiceApi.toggleInvoiceFlag({
					invoiceNumber: id,
					state: true,
					comment: comment,
				})
			} catch (error) {
				spawnErrorToast("Failed to mark transaction as flagged")
			}
		},
		resolveInvoice: async (id: string) => {
			try {
				await invoiceApi.toggleInvoiceFlag({
					invoiceNumber: id,
					state: false,
				})
			} catch (error) {
				spawnErrorToast("Failed to mark transaction as resolved")
			}
		},
	})),
)
