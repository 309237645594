import {
	cycleApi,
	ChangeCycleStatus,
	commentsApi,
	Cycle,
	CommentType,
} from "@carbonbank/api"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { CreateCarbonSavingsComment } from "./types"

type State = {
	data?: Cycle
	isOpen: boolean
	scenario: ScenarioVariant
}

type Actions = {
	openModal: (data: Cycle, scenario: State["scenario"]) => void
	closeModal: () => void
	submitData: (
		cycle: string,
		data: ChangeCycleStatus,
		comment?: CreateCarbonSavingsComment,
	) => Promise<void>
	getScenario: () => ScenarioVariant
}

export type ScenarioVariant = "assure" | "reject" | "requestInformation"

export const useCarbonSavingsModalStore = create<State & Actions>()(
	immer((set, get) => ({
		isOpen: false,
		scenario: "assure",
		data: undefined,

		openModal: (data, scenario) => {
			set(state => {
				state.data = data
				state.scenario = scenario
				state.isOpen = true
			})
		},

		closeModal: () => {
			set(state => {
				state.isOpen = false
			})
		},

		submitData: async (
			cycle: string,
			data: ChangeCycleStatus,
			comment?: CreateCarbonSavingsComment,
		) => {
			await Promise.all([
				cycleApi.changeCycleStatus(cycle, data),
				comment
					? commentsApi.createComment({
							relatedEntityId: cycle.toString(),
							username: comment.username,
							content: comment.content,
							status: comment.status,
							type: CommentType.CarbonSavings,
						})
					: Promise.resolve(),
			])

			set(state => {
				if (state.data?.status) {
					state.data.status = data.status
				}
			})
		},

		getScenario: () => {
			return get().scenario ?? "assure"
		},
	})),
)
