import { carbonBankWithAuthClient } from "@carbonbank/api"

import __mocks__ from "./__tests__/__mocks__"
import {
	GetActivityHistoryResponse,
	GetCertificatesForProject,
	GetProjectDetailResponse,
	GetProjectResponse,
} from "./types"

export const projectsApi = {
	get: (): Promise<GetProjectResponse[]> =>
		carbonBankWithAuthClient
			.get(`/certificates/project-references`)
			.then(response => response.data),

	getById: (id: string): Promise<GetProjectDetailResponse> =>
		carbonBankWithAuthClient
			.get(`/certificates/project-references/${id}`)
			.then(response => response.data),

	getCertificatesForProject: (
		projectId: string,
	): Promise<GetCertificatesForProject[]> =>
		carbonBankWithAuthClient
			.get(`/certificates/project-references/${projectId}/certificates`)
			.then(response => response.data),

	getActivityHistory: (
		projectId: string,
	): Promise<GetActivityHistoryResponse> =>
		carbonBankWithAuthClient
			.get(
				`/certificates/project-references/${projectId}/activity-history`,
			)
			.then(response => response.data),
}

export * from "./types"
