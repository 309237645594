import { clx } from "@sustainability/fundamental"

interface CentralizedContainerBoxProps {
	children?: React.ReactNode
	"data-cy"?: string
	className?: string
}

export const CentralizedContainerBox: React.FC<
	CentralizedContainerBoxProps
> = ({
	children,
	"data-cy": dataCy,
	className,
}: CentralizedContainerBoxProps) => {
	return (
		<div
			data-cy={dataCy}
			className={clx(
				"flex flex-grow items-center justify-center",
				className,
			)}
		>
			{children}
		</div>
	)
}
