import { carbonBankWithAuthClient } from "@carbonbank/api"
import { CustomersResponse } from "@carbonbank/api/customers/types"
import { generateGetUrl } from "@carbonbank/api/utils"

export const customersApi = {
	getCustomers: (): Promise<CustomersResponse> =>
		carbonBankWithAuthClient
			.get(generateGetUrl("/customers"))
			.then(r => r.data),
}
