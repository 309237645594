import { projectsApi } from "@shared/api/projects"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { Project } from "../model"

type State = {
	projects: Project[] | undefined
}

type Actions = {
	fetchProjects: () => Promise<void>
}

export const useProjectReferencesStore = create<State & Actions>()(
	immer(set => ({
		projects: undefined,
		fetchProjects: async () => {
			const response = await projectsApi.get()
			set(state => {
				state.projects = response
			})
		},
	})),
)
