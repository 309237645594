import { carbonBankClient, carbonBankWithAuthClient } from "../client"
import { generateGetUrl } from "../utils"
import {
	TransferValidationForm,
	Transfer,
	TransferSearchParams,
	TransferCertificate,
} from "./types"

export const transferApi = {
	getTransfers: (params?: TransferSearchParams): Promise<Transfer[]> =>
		carbonBankWithAuthClient
			.get("/transfers/all", { params })
			.then(response => response.data),
	getTransferCertificate: (
		transactionId: string,
	): Promise<TransferCertificate> =>
		carbonBankClient
			.get(`/transfers/${transactionId}/certificate`)
			.then(response => response.data),
	downloadEpd: (transferId: string): Promise<BlobPart> =>
		carbonBankWithAuthClient
			.get(generateGetUrl("/transfers/download-epd", { transferId }), {
				responseType: "blob",
			})
			.then(response => response.data),
	updateStatusCertificate: (
		validationIputs: TransferValidationForm,
	): Promise<void> =>
		carbonBankWithAuthClient.post(
			"/transfers/update-status",
			validationIputs,
		),
}
