import { carbonBankWithAuthClient } from "@carbonbank/api"

import {
	CreateCertificateData,
	CreateCertificateResponse,
	PreviousProjectData,
} from "./types"

export const certificatesApi = {
	getPreviousProjects: (): Promise<PreviousProjectData> =>
		carbonBankWithAuthClient
			.get("/certificates/project-references/names")
			.then(response => response.data),
	create: (data: CreateCertificateData): Promise<CreateCertificateResponse> =>
		carbonBankWithAuthClient
			.post("/certificates", data)
			.then(response => response.data),
}
