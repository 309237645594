import { RouteObject } from "react-router-dom"

import { CertificatesValidation } from "./CertificatesValidation"

const baseUrl = "/customers/contracts/validation"

export const contractsRoutes: RouteObject[] = [
	{
		path: baseUrl,
		element: <CertificatesValidation />,
	},
]

export const getCertificatesValidationUrl = () => `${baseUrl}/validation`
