import { useAuthStore } from "@carbonbank/store"
import { CentralizedContainerBox } from "@shared/ui"
import { ErrorActionCard } from "@widgets/error-action-card"
import React from "react"
import { useTranslation } from "react-i18next"

export const AccessDeniedPage: React.FC = () => {
	const { t } = useTranslation("pages", { keyPrefix: "accessDenied" })

	const logout = useAuthStore(state => state.logout)

	return (
		<CentralizedContainerBox data-cy="access-denied-page-container">
			<ErrorActionCard
				title={t("title")}
				description={t("description")}
				type="accessDenied"
				secondaryButtonTitle={t("secondaryButtonTitle")}
				onSecondaryButtonClick={logout}
			/>
		</CentralizedContainerBox>
	)
}
